// global store context
import React, { createContext, useState } from 'react';
import { formatDate } from '../utils/dateRanger';
import { subDays } from 'date-fns';

export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
    const [ user, setUser ] = useState({
        name:"BeautyClick",
        logo:"https://skygardenwe.blob.core.windows.net/media/profiles/9777-437ded29100841f49fa8c844504173b5.jpg"
    });
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const [ dateRange, setDateRange ] = useState({ startDate : formatDate(subDays(new Date(), 6)), endDate : formatDate(new Date()) });
    const [ currentDispatchSla, setCurrentDispatchSla ] = useState('3');
    const [ currentDeliverySla, setCurrentDeliverySla ] = useState('1');
    
    const values = { 
        user, setUser,
        isLoggedIn, setIsLoggedIn, 
        dateRange, setDateRange, 
        currentDispatchSla, setCurrentDispatchSla, 
        currentDeliverySla, setCurrentDeliverySla 
    };
    return (
        <GlobalContext.Provider value={values}>
            {props.children}
        </GlobalContext.Provider>
    )
}

export default GlobalContextProvider;