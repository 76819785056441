// rating service
import axios from "axios";
import { doubleRatingProps } from "../types";

export const ratingService = {
    doubleRating: async ({ public_id, payload, setResponse }:doubleRatingProps)=> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/order/review/${public_id}`, payload);
            setResponse(response)
        } catch(error){
            throw error;
        }
    }
}