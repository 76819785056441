import styled from "styled-components";
import { BsInfoCircle } from 'react-icons/bs';

interface errorProps {
    infoMessage: any,
}

export default function InfoCard ({ infoMessage }:errorProps){
    return (
        <Styles>
            <BsInfoCircle fontSize={50} color="#2196f3"/>
            <div>{infoMessage}</div>
        </Styles>
    )
}

const Styles = styled.div`
    text-align: center;
`