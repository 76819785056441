// tracking page
import { Tracking } from "../components";
import styled from "styled-components";

const TrackingPage = () => {
    return (
        <Page>
            <div className="header">
                <img src={require('../assets/icons/logo-icon.png')} alt="logo" height={30} width={50}/> Order tracking
            </div>
            <div className="centered">
                <Tracking/>
            </div>
            <a href="https://fleet.ke/">
            <div className="footer">
                Powered by Fleet
            </div>
            </a>
        </Page>
    )
}

const Page = styled.div`
    margin: 0;
    padding: 0;

    .header {
        background: #121744;
        color: #ffffff;
        text-align: center;
        padding: 10px;
        
        img {
            vertical-align: middle;
        }
    }

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 6px;

        @media (max-width:700px){
            top: 55%;
            left: 40%;
            min-width: 300px;
        }
        @media (max-width:300px){
            top: 55%;
            left: 50%;
            min-width: 250px;
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        padding: 10px;
        background: #fefefe;
        width: 100%;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
    }
`

export default TrackingPage;