import React from "react";
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
import styled from "styled-components";
import { Bar } from 'react-chartjs-2';
import ReactApexChart from 'react-apexcharts';
import { FiDownload } from 'react-icons/fi';
import Pdf from 'react-to-pdf';
const ref = React.createRef();

const options = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
};

const data = {
    labels: ['January 2022','February 2022', 'March 2022', 'April 2022', 'May 2022', 'June 2022', 'July 2022', 'August 2022', 'September 2022', 'October 2022', 'November 2022', 'December 2022'],
    datasets: [
      {
        label: '1st Time Buyer',
        data: [35, 49, 70, 81, 76, 35, 40, 50, 25, 56, 43, 59],
        fill: true,
        backgroundColor: '#787bfe',
        borderColor: '#787bfe',
        pointBackgroundColor: '#787bfe',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#787bfe'
      },
      {
        label: '2nd Time Buyer',
        data: [45, 69, 90, 81, 56, 55, 60, 80, 45, 56, 76, 72],
        fill: true,
        backgroundColor: '#f4c217',
        borderColor: '#f4c217',
        pointBackgroundColor: '#f4c217',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#f4c217'
      },
      {
        label: '3rd Time Buyer',
        data: [65, 59, 40, 41, 76, 45, 48, 60, 47, 56, 53, 49],
        fill: true,
        backgroundColor: '#fdf3be',
        borderColor: '#fdf3be',
        pointBackgroundColor: '#fdf3be',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#fdf3be'
      },
      {
        label: '4+ Repeat Buyer',
        data: [75, 99, 90, 61, 66, 76, 67, 38, 78, 79, 68, 59],
        fill: true,
        backgroundColor: '#bbbcfb',
        borderColor: '#bbbcfb',
        pointBackgroundColor: '#bbbcfb',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#bbbcfb'
      },
    ],
};

const state = {
    series: [76, 67, 61, 90],
    options: {
      chart: {
        height: 390,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      colors: ['#787bfe', '#f4c217', '#fdf3be', '#bbbcfb'],
      labels: ['1st Time Buyer', '2nd Time Buyer', '3rd Time Buyer', '4+ Repeat Buyer'],
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 160,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
        },
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
              show: false
          }
        }
      }]
    },
  
  
  };

export default function DispatchSlaByCustomerSegment (){
  const { dateRange } = React.useContext(GlobalContext);
    return (
        <Styles>
          <Pdf targetRef={ref} filename="performance-by-customer-segment.pdf">
            {({ toPdf }) => <button onClick={toPdf}><FiDownload/></button>}
          </Pdf>
          <div ref={ref}>
            <h3>Performance by customer segment</h3>
            <p> % of orders dispatch SLA met by customer segment</p>
            <Bar
                options={options}
                data={data}
            />
            <ReactApexChart
                options={state.options} 
                series={state.series} 
                type="radialBar" 
                height={390} 
            />
            <p>{moment(dateRange.startDate).format('LL')} to {moment(dateRange.endDate).format('LL')}</p>
          </div>
        </Styles>
    )   
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 2px #E6ECF1 solid;
    border-radius: 6px;

    button {
      float: right;
      background-color: #0173f6;
      color: #ffffff;
      border: none;
      outline: 0;
      cursor: pointer;
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
`