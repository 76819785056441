import React, { useState } from "react";
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
import styled from "styled-components";
import Pagination from "../../shared/Pagination";
import FleetModal from "../../shared/fleet-modal";
import SingleDriverCost from "./single-driver-cost";
import csvDownload from 'json-to-csv-export';

const drivers = [
    {
        name:'Kimani Kendi',
        orders_delivered:'150',
        orders_failed:'20',
        kms_covered:'600km',
        total_cost:'6000 KES',
        paid_amount:'4500 KES',
        unpaid_amount:'1500 KES',
        bonus:'0 KES',
        tag:'Kosim',
        region: 'Kisumu',
    },
    {
        name:'Kikwetu Kendi',
        orders_delivered:'156',
        orders_failed:'10',
        kms_covered:'360km',
        total_cost:'2300 KES',
        paid_amount:'2000 KES',
        unpaid_amount:'300 KES',
        bonus:'0 KES',
        tag:'Swyft',
        region: 'Nakuru',
    },
    {
        name:'Mutuma Evans',
        orders_delivered:'140',
        orders_failed:'0',
        kms_covered:'340km',
        total_cost:'3400 KES',
        paid_amount:'400 KES',
        unpaid_amount:'3000 KES',
        bonus:'0 KES',
        tag:'Kosim',
        region: 'Kisumu',
    },
    {
        name:'Kwale Mombasa',
        orders_delivered:'154',
        orders_failed:'4',
        kms_covered:'450km',
        total_cost:'4000 KES',
        paid_amount:'4000 KES',
        unpaid_amount:'0 KES',
        bonus:'0 KES',
        tag:'Swyft',
        region: 'Mombasa',
    },
    {
        name:'Francis Atwoli',
        orders_delivered:'15',
        orders_failed:'0',
        kms_covered:'500km',
        total_cost:'7000 KES',
        paid_amount:'4500 KES',
        unpaid_amount:'2500 KES',
        bonus:'0 KES',
        tag:'Swyft',
        region: 'Nairobi',
    },
    
]

const riderCompanies = [ 'All', 'Swyft', 'Kosim' ];

// data to convert to csv
const dataToConvert = {
    data: drivers,
    filename: 'drivers payslips',
    delimiter: ',',
    headers: ['Driver Name', "Orders Delievered", "Orders Failed", "KMs covered", "Total cost", "Paid Amount", "Unpaid Amount", "Bonus", "tag", "Region"]
  }

export default function DriversTable (){
    const { dateRange } = React.useContext(GlobalContext);
    const [ data, setData ] = React.useState(drivers);
    const [ order, setOrder ] = React.useState('ASC');
    const [ selectedRiderCompany, setSelectedRiderCompany ] = React.useState('');
    const [ selectedDriver, setSelectedDriver ] = React.useState('');
    const [ openPayslipModal, setOpenPayslipModal ] = useState(false);

    console.log(selectedRiderCompany);

    const tableSorting = (column) => {
        if(order === 'ASC') {
            const sorted = [...data].sort((a,b)=> a[column].toLowerCase() > b[column].toLowerCase() ? 1 : -1 );
            setData(sorted);
            setOrder('DSC')
        }
        if(order === 'DSC') {
            const sorted = [...data].sort((a,b)=> a[column].toLowerCase() < b[column].toLowerCase() ? 1 : -1 );
            setData(sorted);
            setOrder('ASC')
        }
    }

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [ itemsPerPage ] = useState(3);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Styles>
            <h3>Driver Cost Management</h3>
            <br/>
            <input type="search" placeholder="🔍 Search driver" value='' onChange={()=>null} className="search"/>
            <Select>
                <select onChange={(e)=> setSelectedRiderCompany(e.target.value)}>
                    <option defaultValue=''>Filter by driver tag</option>
                    {riderCompanies?.map((company)=> (
                        <option value={company} key={riderCompanies.indexOf(company)}>
                            {company}
                        </option>
                    ))}
                </select>
            </Select>
            <Table>
                <thead>
                    <tr>
                        <th onClick={()=> tableSorting('name')}>Driver Name<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('tag')}>Driver Tag<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('region')}>Region<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('orders_delivered')}>Orders Delivered<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('orders_failed')}>Return Orders<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('kms_covered')}>Distance Covered<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('total_cost')}>Total Cost<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('paid_amount')}>Paid Amount<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('unpaid_amount')}>Unpaind Amount<i className="fa fa-sort"></i></th>
                        <th onClick={()=> tableSorting('bonus')}>Bonus<i className="fa fa-sort"></i></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { paginatedItems.map((driver) => (
                            <tr key={drivers.indexOf(driver)} onClick={()=> {
                                setSelectedDriver(driver)
                                setOpenPayslipModal(true)
                            }}>
                                <td>{driver.name}</td>
                                <td>{driver.tag}</td>
                                <td>{driver.region}</td>
                                <td>{driver.orders_delivered}</td>
                                <td>{driver.orders_failed}</td>
                                <td>{driver.kms_covered}</td>
                                <td>{driver.total_cost}</td>
                                <td>{driver.paid_amount}</td>
                                <td>{driver.unpaid_amount}</td>
                                <td>{driver.bonus}</td>
                                <td className="action">Manage</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={drivers.length}
                paginate={(pageNumber)=> setCurrentPage(pageNumber)}
            />
            <br/>
            <p>{moment(dateRange.startDate).format('LL')} to {moment(dateRange.endDate).format('LL')}</p>
            <p style={{ color: '#0969da', cursor: 'pointer' }} onClick={() => csvDownload(dataToConvert)}>export to csv</p>

            {/* modals */}
            <FleetModal marginTop="90%" showModal={openPayslipModal} setShowModal={setOpenPayslipModal} title="Driver payslip">
                <SingleDriverCost driver={selectedDriver}/>
            </FleetModal>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 20px;
    margin: 10px;
    border: 1px #E6ECF1 solid;
    background-color: #ffffff;
    
    .search {
        border: none;
        border-bottom: 2px #E6ECF1 solid;
        border-radius: 0px;
        border: 1px #ddd solid;
        border-radius: 6px;
        padding: .5rem;
        margin-right: 10px;
        outline: none;
    }
`

const Table = styled.table`
    text-align: left;
    border-collapse: collapse;
    overflow-x: scroll;
    
    img { vertical-align: middle; }

    th { 
        font-weight: bold;
        cursor: pointer;

        .fa {
            font-size: 10px;
        }
    }

    th, td {
        padding: 1rem .8rem;
        border-bottom: 1px #f0f0f0 solid;
    }

    .active-row {
        background-color: #f0f0f0;
    }

    tbody {
        tr:nth-child(even){
            background-color: #f2f2f2;
        }
        tr:hover {
            /* background-color: #bbbaff;
            cursor: pointer; */
        }
        .action {
            color: #0969da;
            cursor: pointer;
        }
    }
`

const Select = styled.span`
    margin-right: 20px;

    select {
        border: 1px #ddd solid;
        border-radius: 6px;
        padding: .5rem 0;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        /* color: #E6ECF1; */
        -webkit-appearance:none;

        option {
            span {
                margin: .3rem;
            }
        }
    }
`
