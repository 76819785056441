import React, { useState } from "react";
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
import styled from "styled-components";
import Pagination from "../../shared/Pagination";
import { FiDownload } from 'react-icons/fi';
import Pdf from 'react-to-pdf';
const ref = React.createRef();
const options = { orientation: 'landscape' };

const slas = [
    {
        date:'04-01-2023',
        zone:'Zone A',
        percentage_dispatch_sla_met:'50%',
        percentage_dispatch_sla_not_met:'50%',
        logged_in_dispatcher:'uhuru kenyatta'
    },
    {
        date:'05-01-2023',
        zone:'Zone B',
        percentage_dispatch_sla_met:'80%',
        percentage_dispatch_sla_not_met:'20%',
        logged_in_dispatcher:'raila odinga'
    },
    {
        date:'06-01-2023',
        zone:'Zone C',
        percentage_dispatch_sla_met:'70%',
        percentage_dispatch_sla_not_met:'30%',
        logged_in_dispatcher:'william ruto'
    },
    {
        date:'06-01-2023',
        zone:'Zone D',
        percentage_dispatch_sla_met:'90%',
        percentage_dispatch_sla_not_met:'10%',
        logged_in_dispatcher:'mwai kibaki'
    },
]

const buyers = [ 'first time buyer', 'second time buyer', 'repeat buyer'];
const dispatchers = [ 'all dispatchers', 'dispatcher A', 'dispatcher B'];
const zones = [ 'Zone A', 'Zone B', 'Zone C', 'Zone D'];

export default function DispatchSlaTable (){
    const { dateRange } = React.useContext(GlobalContext);
    const [ data, setData ] = React.useState(slas);
    const [ order, setOrder ] = React.useState('ASC');
    const [ selectedBuyer, setSelectedBuyer ] = React.useState('');
    const [ selectedDispatcher, setSelectedDispatcher ] = React.useState('');
    const [ selectedZone, setSelectedZone ] = React.useState('');

    console.log(selectedBuyer, selectedDispatcher, selectedZone);

    const tableSorting = (column) => {
        if(order === 'ASC') {
            const sorted = [...data].sort((a,b)=> a[column].toLowerCase() > b[column].toLowerCase() ? 1 : -1 );
            setData(sorted);
            setOrder('DSC')
        }
        if(order === 'DSC') {
            const sorted = [...data].sort((a,b)=> a[column].toLowerCase() < b[column].toLowerCase() ? 1 : -1 );
            setData(sorted);
            setOrder('ASC')
        }
    }


    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [ itemsPerPage ] = useState(3);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Styles>
            <Pdf targetRef={ref} options={options} filename="dispatch-sla-table.pdf">
                {({ toPdf }) => <button onClick={toPdf}><FiDownload/></button>}
            </Pdf>
            <div ref={ref}>
                <h3>Dispatch SLA table</h3>
                <Select>
                    <select onChange={(e)=> setSelectedBuyer(e.target.value)}>
                        <option defaultValue=''>select buyer</option>
                        {buyers?.map((buyer)=> (
                            <option value={buyer} key={buyers.indexOf(buyer)}>
                                {buyer}
                            </option>
                        ))}
                    </select>
                </Select>
                <Select>
                    <select onChange={(e)=> setSelectedDispatcher(e.target.value)}>
                        <option defaultValue=''>select dispatcher</option>
                        {dispatchers?.map((dispatcher)=> (
                            <option value={dispatcher} key={dispatchers.indexOf(dispatcher)}>
                                {dispatcher}
                            </option>
                        ))}
                    </select>
                </Select>
                <Select>
                    <select onChange={(e)=> setSelectedZone(e.target.value)}>
                        <option defaultValue=''>select zone</option>
                        {zones?.map((zone)=> (
                            <option value={zone} key={zones.indexOf(zone)}>
                                {zone}
                            </option>
                        ))}
                    </select>
                </Select>

                <Table>
                    <thead>
                        <tr>
                            <th onClick={()=> tableSorting('date')}>Date <i className="fa fa-sort"></i></th>
                            <th onClick={()=> tableSorting('zone')}>Zone <i className="fa fa-sort"></i></th>
                            <th onClick={()=> tableSorting('percentage_dispatch_sla_met')}>% SLA met <i className="fa fa-sort"></i></th>
                            <th onClick={()=> tableSorting('percentage_dispatch_sla_not_met')}>% SLA not met <i className="fa fa-sort"></i></th>
                            <th onClick={()=> tableSorting('logged_in_dispatcher')}>Logged in Dispatcher <i className="fa fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        { paginatedItems.map((sla) => (
                                <tr key={slas.indexOf(sla)} >
                                    <td>{sla.date}</td>
                                    <td>{sla.zone}</td>
                                    <td>{sla.percentage_dispatch_sla_met}</td>
                                    <td>{sla.percentage_dispatch_sla_not_met}</td>
                                    <td>{sla.logged_in_dispatcher}</td>
                                    <td></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={slas.length}
                    paginate={(pageNumber)=> setCurrentPage(pageNumber)}
                />

                <br/>
                <p>{moment(dateRange.startDate).format('LL')} to {moment(dateRange.endDate).format('LL')}</p>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 2px #E6ECF1 solid;
    border-radius: 6px;

    button {
      float: right;
      background-color: #0173f6;
      color: #ffffff;
      border: none;
      outline: 0;
      cursor: pointer;
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
`

const Table = styled.table`
    text-align: left;
    border-collapse: collapse;
    overflow-x: scroll;
    
    img { vertical-align: middle; }

    th { 
        font-weight: bold;
        cursor: pointer;

        .fa {
            font-size: 10px;
        }
    }

    th, td {
        padding: 1rem .8rem;
        border-bottom: 1px #f0f0f0 solid;
    }

    .active-row {
        background-color: #f0f0f0;
    }

    tbody {
        tr:nth-child(even){
            background-color: #f2f2f2;
        }
        tr:hover {
            background-color: #bbbaff;
            cursor: pointer;
        }
    }
`

const Select = styled.span`
    margin-right: 20px;

    select {
        /* border: 1px #ddd solid; */
        border-radius: 6px;
        padding: .5rem 0;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        /* color: #E6ECF1; */

        option {
            span {
                margin: .3rem;
            }
        }
    }
`
