import React from "react";
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
// import { fetchDispatchSlaByZone } from '../../../api/dispatchSlaService';
import styled from "styled-components";
import { HorizontalBar } from 'react-chartjs-2';
import { FiDownload } from 'react-icons/fi';
import Pdf from 'react-to-pdf';
const ref = React.createRef();


const options = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
    tooltips: {
        callbacks: {
            label: (tooltipItem) => Math.abs(tooltipItem.xLabel)
        }
    },
    scales: {
        xAxes: [
          {
            ticks: { 
                callback: (val)=> Math.abs(val)
            },
          },
        ],
    }
};

const data = {
    labels: ['Zone A','Zone B', 'Zone C', 'Zone D'],
    datasets: [
      {
        label: "Within SLA",
        data: [-70, -50, -60, -80],
        borderColor: '#7779ff',
        backgroundColor: '#7779ff',
      },
      {
        label: "Outside SLA",
        data: [30, 50, 40, 20],
        borderColor: '#f4c315',
        backgroundColor: '#f4c315',
      },
    ],
  };

export default function DispatchSlaByZone (){
    const { dateRange } = React.useContext(GlobalContext);
    // const [ loading, setLoading ] = React.useState(false);
    // const [ dispatchSlaByZoneData, setDispatchSlaByZoneData ] = React.useState('');

    // React.useEffect(()=> {
    //   fetchDispatchSlaByZone({ setLoading, setDispatchSlaByZoneData })
    // },[])


    return (
        <Styles>
          <Pdf targetRef={ref} filename="dispatch-sla-by-zone.pdf">
            {({ toPdf }) => <button onClick={toPdf}><FiDownload/></button>}
          </Pdf>
          <div ref={ref}>
              <h3>Dispatch SLA by zone</h3>
              {/* {loading ? 'loading' : JSON.stringify(dispatchSlaByZoneData)} */}
              <HorizontalBar
                  options={options}
                  data={data}
              />
              <p>{moment(dateRange.startDate).format('LL')} to {moment(dateRange.endDate).format('LL')}</p>
          </div>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 2px #E6ECF1 solid;
    border-radius: 6px;

    button {
      float: right;
      background-color: #0173f6;
      color: #ffffff;
      border: none;
      outline: 0;
      cursor: pointer;
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
`