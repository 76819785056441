// location service
import axios from 'axios';
import { countriesProps, timezonesProps } from '../types';

export const locationService = {
    // get all countries
    getAllCountries: async ({ setCountries, setLoadingCountries, setErrorCountries }:countriesProps) =>{
        setLoadingCountries(true);
        try {
            const response = await axios.get(`https://restcountries.com/v3.1/all`);
            setLoadingCountries(false);
            setCountries(response.data)
        } catch(error:any) {
            setLoadingCountries(false);
            setErrorCountries(error.response.data.error ? error.response.data.error : error.message)
            throw error
        }
    },

    // get world timezones
    getWorldTimezones: async ({ setTimezones, setLoadingTimezones, setErrorTimezones }:timezonesProps) =>{
        setLoadingTimezones(true);
        try {
            const response = await axios.get(`https://worldtimeapi.org/api/timezone`);
            setLoadingTimezones(false);
            setTimezones(response.data)
        } catch(error:any) {
            setLoadingTimezones(false);
            setErrorTimezones(error.response.data.error ? error.response.data.error : error.message)
            throw error
        }
    }
}