import styled from 'styled-components';
import { 
    Topbar,
    AnalytcisNavigation,
    DeliverySlaEditor, 
} from '../../components';

import DeliverySlaByDriver from '../../components/analytics/delivery/DeliverySlaByDriver';
import DeliverySlaByDate from '../../components/analytics/delivery/DeliverySlaByDate';
import DeliveryMissedSlaByCustomer from '../../components/analytics/delivery/DeliveryMissedSlaByCustomer';
import DeliverySlaTable from '../../components/analytics/delivery/DeliverySlaTable';
import FailedAndSuccessfulDeliveriesByZone from '../../components/analytics/delivery/FailedDeliveryByZone';
import FailedAndSuccessfulDeliveriesByDate from '../../components/analytics/delivery/FailedDeliveryByDate';

export default function DeliverySLA () {
    return (
        <Styles>
            <Topbar/>
            <AnalytcisNavigation/>
            <DeliverySlaEditor/>
            <div className='wrapper'>
                <DeliverySlaByDriver/>
                <DeliverySlaByDate/>
                <DeliveryMissedSlaByCustomer/>
                <DeliverySlaTable/>
                <FailedAndSuccessfulDeliveriesByZone/>
                <FailedAndSuccessfulDeliveriesByDate/>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        >div {
            margin: 10px;
            padding: 10px;
            flex: 40%;
        }
    }
`