import { useState } from "react";
import styled from "styled-components";
import Tooltip from "../../shared/Tooltip";
import Button from "../../shared/Button";

export default function CreatePricingModel () {
    const [ name, setName ] = useState('');
    const [ withholdingTax, setWithholdingTax ] = useState('');
    const [ vat, setVat ] = useState('');
    const [ activeTab, setActiveTab ] = useState('1');

    const [ baseRateDistance, setBaseRateDistance ] = useState('');
    const [ baseRateAmount, setBaseRateAmount ] = useState('');

    const [ costPerKm, setCostPerKm ] = useState('');
    
    const vehilceSizes = [ '2ton lorry', '5ton lorry', '10ton truck' ];

    const deliveryZones = [ 'westlands', 'kikuyu', 'momabasa road' ];
    const [ selectedDeliveryZone, setSelectedDeliveryZone ] = useState('');
    const [ costPerDeliveryZone, setCostPerDeliveryZone ] = useState('');

    const [ perDayLeaseVehicle, setPerDayLeaseVehicle ] = useState('');
    const [ perDayLeasePrice, setPerDayLeasePrice ] = useState('');
    const [ perMonthLeaseVehicle, setPerMonthLeaseVehicle ] = useState('');
    const [ perMonthLeasePrice, setPerMonthLeasePrice ] = useState('');

    return (
        <Styles>
            {/* company name and wtax */}
            <div className="info">
                <img src={require('../../../assets/icons/info.png')} alt="info" className="info-icon" height={20} />
                <span>Please set the tax type that applies to your pricing. Set the pricing model that applies to the company pricing.</span>
            </div>

            <div className="section">
                <div className="">
                    <label>Company Name</label><br/>
                    <input type="text" required placeholder="" value={name} onChange={(e)=> setName(e.target.value)}/>
                </div>
            </div>

            <div className="section">
                <div className="">
                    <label>Withholding Tax</label><Tooltip label="info?" toolTipInfo="Withholding Income Tax is tax withheld at source."/><br/>
                    <input type="number" placeholder="" value={withholdingTax} onChange={(e)=> setWithholdingTax(e.target.value)}/> %
                </div>
                <div className="">
                    <label>VAT</label><Tooltip label="info?" toolTipInfo="VAT is country specific and each country has it's own charges. So kindly set the vat based on your country requirements."/><br/>
                    <input type="number" placeholder="" value={vat} onChange={(e)=> setVat(e.target.value)}/> %
                </div>
            </div>

            {/* navigations */}
            <div className="navigations">
                <span style={activeTab === '1' ? { color: '#0173f6', borderBottom: '2px #0173f6 solid' } : null} onClick={()=>setActiveTab('1')}>
                    Base Rate
                </span>
                <span style={activeTab === '3' ? { color: '#0173f6', borderBottom: '2px #0173f6 solid' } : null} onClick={()=>setActiveTab('3')}>
                    Vehicle lease
                </span>
                <span style={activeTab === '4' ? { color: '#0173f6', borderBottom: '2px #0173f6 solid' } : null} onClick={()=>setActiveTab('4')}>
                    Per delivery
                </span>
            </div>

            {/* base rate */}
            {activeTab === '1' ?
                <div>
                    <p><b>Base Rate</b></p>
                    <div className="section">
                        <div className="">
                            <label>Range: 0 - {baseRateDistance ? baseRateDistance : '**'}KM ({baseRateAmount}ksh)</label> <br/>
                            <input type="number" placeholder="" value={baseRateDistance} onChange={(e)=> setBaseRateDistance(e.target.value)}/>
                        </div>
                        <div className="">
                            <label>Amount</label><br/>
                            <input type="number" placeholder="" value={baseRateAmount} onChange={(e)=> setBaseRateAmount(e.target.value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="">
                            <p><b>Cost per km after 0 - {baseRateDistance ? baseRateDistance : '**'}KM:</b>KES {costPerKm}</p>
                            <label>Amount</label><br/>
                            <input type="number" placeholder="" value={costPerKm} onChange={(e)=> setCostPerKm(e.target.value)}/>
                        </div>
                    </div>
                </div>
                : null
            }

            {/* per vehicle size lease on Daily and Monthly */}
            {activeTab === '3' ?
                <div>
                    <p><b>Lease cost per Day: </b>{perDayLeaseVehicle} for KES {perDayLeasePrice}</p>
                    <div className="section">
                        <div className="">
                            <Select>
                                <select onChange={(e)=> setPerDayLeaseVehicle(e.target.value)}>
                                    <option defaultValue=''>vehicle size</option>
                                    {vehilceSizes?.map((vehicle)=> (
                                        <option value={vehicle} key={vehilceSizes.indexOf(vehicle)}>
                                            {vehicle}
                                        </option>
                                    ))}
                                </select>
                            </Select>
                        </div>
                        <div className="">
                            <label>Amount</label>
                            <input type="number" placeholder="" value={perDayLeasePrice} onChange={(e)=> setPerDayLeasePrice(e.target.value)}/>
                        </div>
                    </div>

                    {/* <hr/> */}
                    <p><b>Lease cost per Month: </b>{perMonthLeaseVehicle} for KES {perMonthLeasePrice}</p>
                    <div className="section">
                        <div className="">
                            <Select>
                                <select onChange={(e)=> setPerMonthLeaseVehicle(e.target.value)}>
                                    <option defaultValue=''>vehicle size</option>
                                    {vehilceSizes?.map((vehicle)=> (
                                        <option value={vehicle} key={vehilceSizes.indexOf(vehicle)}>
                                            {vehicle}
                                        </option>
                                    ))}
                                </select>
                            </Select>
                        </div>
                        <div className="">
                            <label>Amount</label>
                            <input type="number" placeholder="" value={perMonthLeasePrice} onChange={(e)=> setPerMonthLeasePrice(e.target.value)}/>
                        </div>
                    </div>
                </div> : null
            }

            {/* per vehicle delivery zones */}
            {activeTab === '4' ?
                <div className="">
                    <p><b>Cost per delivery: </b>{selectedDeliveryZone} for KES {costPerDeliveryZone}</p>
                    <div className="section">
                        <div className="">
                            <label>Delivery zone</label><br/>
                            <Select>
                                <select onChange={(e)=> setSelectedDeliveryZone(e.target.value)}>
                                    <option defaultValue=''>select</option>
                                    {deliveryZones?.map((zone)=> (
                                        <option value={zone} key={deliveryZones.indexOf(zone)}>
                                            {zone}
                                        </option>
                                    ))}
                                </select>
                            </Select>
                        </div>
                        <div className="">
                            <label>Amount</label><br/>
                            <input type="number" placeholder="" value={costPerDeliveryZone} onChange={(e)=> setCostPerDeliveryZone(e.target.value)}/>
                        </div>
                    </div>
                </div> : null
            }

            {/* submit button */}
            <div className="">
                <Button
                    label="Create"
                    onClick={()=> null}
                    width="fit-content"
                    borderRadius="4px"
                    background="#121744"
                    border="none"
                    padding="10px 20px"
                    margin="10px"
                />
            </div>
            
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;

    .info {
        background-color: #FFECAD;
        padding: 5px;
        margin-bottom: 20px;
        font-size: 12px;
        width: fit-content;

        .info-icon {
            margin: 5px;
            vertical-align: middle;
        }
    }
    
    .section {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        /* >div {
            margin: 10px;
            padding: 10px;
            flex: 40%;
        } */
    }






    input {
        /* border: none; */
        border: 2px #E6ECF1 solid;
        border-radius: 4px;
        padding: 10px;
        margin-top: 5px;
        margin-right: 10px;
        outline: none;
        /* width: 50px; */
    }
    .navigations {
        padding-top: 10px;
        margin: 20px;
        background-color: #f2f2f2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: bold;

        span {
            cursor: pointer;
            padding: 10px;
        }
    }
    .submit {
        background-color: #0173f6;
        color: #ffffff;
        border: none;
        outline: 0;
        padding: 10px;
        width: 200px;
    }
`
const Select = styled.span`
    margin-right: 20px;

    select {
        /* border: none; */
        border: 2px #E6ECF1 solid;
        border-radius: 4px;
        padding:7px 10px;
        margin: 5px 0;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        color: inherit;

        option {
            span {
                margin: .3rem;
            }
        }
    }
`