// auth service
import axios from "axios";
import { signupProps } from "../types";
import swal from "sweetalert";

export const authService = {
    signup: async ({signupPayload, setLoading, setError, setResponse}:signupProps) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/create`, signupPayload)
            setLoading(false);
            if(response.status === 200){
                setResponse(response)
                console.log(response)
                setTimeout(() => { window.location.href=`${process.env.REACT_APP_LOGIN_URL}`; }, 3000);
            }
        } catch(error:any){
            if(navigator.onLine){
                setLoading(false)
                setError(error.response.data.errors);
                throw error
            } else {
                swal({
                    icon: "info",
                    text: "check your internet connection"
                })
            }  
        }
    }
}