import styled from "styled-components";

interface ProgressProps {
    progress: string
}

export default function StepsProgressBar (props:ProgressProps){
    const {  progress } = props;
    return (
        <Styles>
            <section className="section" style={{ backgroundColor: `${(progress === 'processed'|| progress === 'shipping' || progress === 'complete' ) ? '#286ff4' : ''}`}}>
                <img 
                    style={{ backgroundColor: `${(progress === 'processed'|| progress === 'shipping' || progress === 'complete' ) ? '#286ff4' : ''}`}}
                    src={require('../../assets/icons/shopping-bag.png')} 
                    className="icon" 
                    alt="" 
                    height={40}
                />
                <div className="info">
                    <b>Processed</b>
                    <p>Your order <b>#4368792</b> was processed and disptched on 22nd Feb 2023, 9:20am</p>
                </div>
            </section>
            <section className="section" style={{ backgroundColor: `${(progress === 'shipping' || progress === 'complete' ) ? '#286ff4' : ''}`}}>
                <img 
                    style={{ backgroundColor: `${(progress === 'shipping' || progress === 'complete' ) ? '#286ff4' : ''}`}}
                    src={require('../../assets/icons/rider.png')} 
                    className="icon" 
                    alt="" 
                    height={40} 
                />
                <div className="info">
                    <b>On the away</b>
                    <p>Your order <b>#4368792</b> was picked up by driver (Kariki) on 22nd Feb 2023, 10:20am and is expected to arrive on 22nd Feb 2023, 12:20pm</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8467480541526!2d36.80492761494095!3d-1.2644668359598665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f176afe68fe1b%3A0xf590c6d95ac4594f!2sWestpark%20Towers!5e0!3m2!1sen!2ske!4v1677074821370!5m2!1sen!2ske"
                        title="This is a unique title"
                        width="100%"
                        height="400px"
                        style={{ border: 0 }}
                        allowFullScreen={true}
                    />
                </div>
            </section>
            <section className="section" style={{ backgroundColor: `${(progress === 'complete' ) ? '#286ff4' : ''}`}}>
                <img 
                    style={{ backgroundColor: `${(progress === 'complete' ) ? '#286ff4' : ''}`}}
                    src={require('../../assets/icons/check.png')} 
                    className="icon" 
                    alt="" 
                    height={40} 
                />
                <div className="info">
                    <b>Delivered</b>
                    {/* <p>Your order <b>#4368792</b> was delievered by driver (Kariki) on 22nd Feb 2023, 12:20pm</p> */}
                </div>
            </section>
        </Styles>
    )
}

const Styles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
    text-align: center;

    .section {
        height: 10px;
        background-color: #c1c4c8;
        width: 100%;

        .icon {
            background-color: #c1c4c8;
            padding: 10px;
            border-radius: 50%;
            margin-top: -20px;
            margin-left: -5px;
        }

        .info {
            /* text-align: left; */
            padding: 20px;
        }
    }
`