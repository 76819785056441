import { useContext } from "react";
import styled from "styled-components";
import { FaAngleDown } from 'react-icons/fa';
import { GlobalContext } from '../../context/GlobalContext';

const Topbar = () => {
    const { user } = useContext(GlobalContext);
    return (
        <Styles>
            <section>
                <img src={require('../../assets/icons/logo-icon.png')} className="logo" alt="logo" height={30} width={40}/> <b>Fleet</b>
            </section>
            <section>
                {user.name}
                <img src={require('../../assets/icons/avatar.png')} alt="avatar" className="avatar"height={30} width={30}/>
                <FaAngleDown/>
            </section>
        </Styles>
    )
}

const Styles = styled.div`
    background: #121744;
    color: #ffffff;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
        vertical-align: middle;
    }

    .avatar {
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
    }
    svg {
        vertical-align: middle;
        margin-left: 5px;
    }
`

export default Topbar;