//form validations

// email validation
export const emailValidation = (email:string) => {
  let error:any = {};
  if(email){
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(email.match(emailRegex)) {
      error.message = ""; // valid
      error.status = true;
    } else {
       error.message = "Please add a vaild email address*";
       error.status = false;
    }
    return error;

  } else {
    return error.message = "email required*"
  }
}

// password validation
export const passwordValidation = (password:string) => {
  let error:any = {};
  if(password){
    if(password.length > 6) {
      error.message = ""; // vaild
      error.status = true;
    } else {
      error.message = "Weak password! (Password must be greater than 6 characters)*";
      error.status = false;
    }
    return error;
    
  } else {
    return error.message = "password required"
  }
}