// signup
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Input, Button } from "../../components";
import { locationService } from "../../apis/location-service";
//@ts-ignore
import useGeoLocation from "react-ipgeolocation";

// form validations
import { emailValidation, passwordValidation } from "../../utils/formValidations";

interface signUpProps {
    country: string
    selectedTimezone: string,
    username: string,
    email: string,
    password: string,
    step: number,
    setCountry: (value: string) => void,
    setSelectedTimezone: (value: string) => void,
    setUserName: (value: string) => void,
    setEmail: (value: string) => void,
    setPassword: (value: string) => void,
    setStep: (value: number) => void;
}

const Signup  = ({ country, selectedTimezone, username, email, password, setCountry, setSelectedTimezone, setUserName, setEmail, setPassword, step, setStep }:signUpProps) => {
    const [ submit, setSubmit ] = useState(false);

    // fetch countries for select
    const [ countries, setCountries ] = useState<any>([]);
    const [ loadingCountries, setLoadingCountries ] = useState<boolean>(false);
    const [ errorCountries, setErrorCountries ] = useState<any>(null);
  
    // fetch timezones for select
    const [ timezones, setTimezones ] = useState<any>([]);
    const [ loadingTimezones, setLoadingTimezones ] = useState<boolean>(false);
    const [ errorTimezones , setErrorTimezones ] = useState<any>(null);

    // get country using ip
    const location = useGeoLocation();
    const currentCountry = location.country;

    // get current timezone 
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(()=> {
        locationService.getAllCountries({ setCountries, setLoadingCountries, setErrorCountries });
        locationService.getWorldTimezones({ setTimezones, setLoadingTimezones, setErrorTimezones });
    },[])

    // auto select country if matches ip
    useEffect(()=> {
        countries?.map((country:any) => country.cca2 === currentCountry ? setCountry(country.name.common): null);
        timezones?.map((timezone:string) => timezone === currentTimezone ? setSelectedTimezone(timezone) : null);
    },[countries, currentCountry, setCountry, timezones, currentTimezone, setSelectedTimezone])

    // submit and validations
    const handleSubmit = (event:any) => {
        event.preventDefault();
        setSubmit(true)
        if(country && selectedTimezone && username && emailValidation(email).status && passwordValidation(password).status ){
            setStep(step +1);
        }
    }

    return (
        <Styles>
            <form className="signup-form" onSubmit={handleSubmit}>
                <div className="signup-form-content">
                    <h2>Create your account</h2>
                    <label>Username</label>
                        <Input
                            value={username}
                            placeholder=""
                            onChange={(e)=> setUserName(e.target.value)}
                            required
                        />
                    <br/><br/>
                    <label>Email</label>
                        {email ? <div className="error-message">{emailValidation(email).message}</div> : null}
                        <Input
                            type="email"
                            value={email}
                            placeholder=""
                            onChange={(e)=> setEmail(e.target.value)}
                            required
                        />
                    <br/><br/>
                    <label>Password</label>
                        {(submit || password) ? <div className="error-message">{passwordValidation(password).message }</div>: null}
                        <Input
                            type="password"
                            value={password}
                            placeholder=""
                            onChange={(e)=> setPassword(e.target.value)}
                            required
                        />
                    <br/><br/>
                    <label>Country of operation</label><br/>
                        <select onChange={(e)=> setCountry(e.target.value)} required>
                            <option value={country}>{country ? country : loadingCountries ? 'fetching...' : errorCountries ? 'error' : 'Select country'}</option>
                            {countries?.map((item:any)=> (
                                <option value={item.name.common} key={countries.indexOf(item)}>
                                    {item.name.common}
                                </option>
                            ))}
                        </select>
                    <br/>
                    <label>Operating Timezone</label><br/>
                        <select onChange={(e)=> setSelectedTimezone(e.target.value)} required>
                            <option value={selectedTimezone}>{selectedTimezone ? selectedTimezone : loadingTimezones ? 'fetching...': errorTimezones ? 'error' : 'Select timezone'}</option>
                            {timezones?.map((timezone:any)=> (
                                <option value={timezone} key={timezones.indexOf(timezone)}>
                                    {timezone}
                                </option>
                            ))}
                        </select>
                </div>
                <div className="navigate">
                    <Button
                        type="submit"
                        label="Next"
                        width="fit-content"
                        borderRadius="5px"
                        background="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                </div>
            </form>
        </Styles>
    )
}

const Styles = styled.div`
    .signup-form {
        padding: 0;
        margin: 0;
        
        .signup-form-content {
            padding: 5% 10%;
            max-width: 400px;

            h2 { 
                color:#121744; 
            }
            label {
                font-weight: bold; 
            }
            select {
                width: calc(100% + 20px);
            }
            .error-message {
                color: red;
                font-size: 10px;
                font-style: italic;
            }
        }

        .navigate {
            bottom: 0;
            position: fixed;
            width: 70%;
            background-color: #f3f5f6;
            text-align: right;

            @media(max-width:1000px){
                width: 100%;
            }
        }
    }
`

export default Signup;