// driver cost analytics
import React, { useState } from "react";
import styled from "styled-components";
import { Bar, Line } from "react-chartjs-2";
import Pdf from 'react-to-pdf';
const ref = React.createRef();

const options = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
};

const data = {
    labels: ['February 2022', 'March 2022', 'April 2022', 'May 2022', 'June 2022', 'July 2022', 'August 2022', 'September 2022', 'October 2022', 'November 2022', 'December 2022', 'January 2023',],
    datasets: [
        {
            label: 'Logistics costs',
            data: [220000, 231000, 525000, 112000, 227000, 398000, 439000, 359000, 39000, 779000, 739000, 145000],
            borderColor: '#0173f6',
            backgroundColor: '#6eb1fe',
        }
    ],
};



export default function DriverCostChartMonthly (){
  const [ showBarChart, setShowBarChart ] = useState(true);
    return (
        <Styles>
            <Pdf targetRef={ref} filename="logistics-cost.pdf">
                {({ toPdf }) => <button className="download" onClick={toPdf}>Download</button>}
            </Pdf>
            <p style={{color: '#0969da', cursor:'pointer' }} onClick={()=> setShowBarChart(!showBarChart)}>{showBarChart ? 'show line chart' : 'show bar chart'}</p>
            <div ref={ref}>
                <h3>Logistics costs (monthly)</h3>
                {showBarChart ?
                    <Bar
                        options={options}
                        data={data}
                    /> :
                    <Line
                        options={options}
                        data={data}
                    />
                }
            </div>
        </Styles>
    )   
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 1px #E6ECF1 solid;
    background-color: #ffffff;

    .download {
      float: right;
      background-color:transparent;
      color: #0173f6;
      border: none;
      outline: 0;
    }
`