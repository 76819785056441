// team name
import styled from "styled-components";
import { Input, Button } from "../../components";

interface teamProps {
    teamName: string,
    teamSize: string,
    companyDescription: string,
    step: number,
    setTeamName: (value: string) => void,
    setTeamSize: (value: string) => void,
    setCompanyDescription: (value: string) => void,
    setStep: (value: number) => void;
}

const companyDescriptions = ['Courier, post', 'Retail', 'Beverage', 'Hot meals', 'Medical, pharmacy', 'Produce, farm', 'Other'];
const teamSizes = ['1', '2 - 5', '6 - 10', '11 - 20', '21 - 50', '51+'];

const TeamName  = ({ teamName, teamSize, companyDescription, setTeamName, setTeamSize, setCompanyDescription, step, setStep }:teamProps) => {

    // submit and validations
    const handleSubmit = (event:any) => {
        event.preventDefault();

        if(teamName && teamSize && companyDescription){
            setStep(step +1);
        }
    }

    return (
        <Styles>
            <form className="signup-form" onSubmit={handleSubmit}>
                <div className="signup-form-content">
                    <h2>What's your organisation called?</h2>
                    <label>Organisation name</label>
                        <Input
                            value={teamName}
                            placeholder=""
                            onChange={(e)=> setTeamName(e.target.value)}
                            required
                        />
                    <br/><br/>
                    <label>What describes your company best?</label><br/>
                        <select onChange={(e)=> setCompanyDescription(e.target.value)} required>
                            <option value={companyDescription}>{companyDescription ? companyDescription : 'Select'}</option>
                            {companyDescriptions?.map((description)=> (
                                <option value={description} key={companyDescriptions.indexOf(description)}>
                                    {description}
                                </option>
                            ))}
                        </select>
                    <br/><br/>
                    <label>Organisation size</label><br/>
                        <select onChange={(e)=> setTeamSize(e.target.value)} required>
                            <option value={teamSize}>{teamSize ? teamSize : 'Select'}</option>
                            {teamSizes?.map((size)=> (
                                <option value={size} key={teamSizes.indexOf(size)}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    <br/><br/>
                </div>
                <div className="navigate">
                    <Button
                    onClick={()=> setStep(step -1)}
                        label="Previous"
                        width="fit-content"
                        borderRadius="5px"
                        background="transparent"
                        color="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                    <Button
                        type="submit"
                        label="Next"
                        width="fit-content"
                        borderRadius="5px"
                        background="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                </div>
            </form>
        </Styles>
    )
}

const Styles = styled.div`
    .signup-form {
        padding: 0;
        margin: 0;
        
        .signup-form-content {
            padding: 5% 10%;
            max-width: 400px;

            h2 { 
                color:#121744; 
            }
            label {
                font-weight: bold; 
            }
            select {
                width: calc(100% + 20px);
            }
            .error-message {
                color: red;
                font-size: 10px;
                font-style: italic;
            }
        }

        .navigate {
            bottom: 0;
            position: fixed;
            width: 70%;
            background-color: #f3f5f6;
            text-align: right;

            @media(max-width:1000px){
                width: 100%;
            }
        }
    }
`
export default TeamName;