import styled from "styled-components";
import StarIcon from "../../assets/icons/star.png";
import StarIconFilled from "../../assets/icons/star-orange.png";
import PackageIcon from "../../assets/icons/package-icon.png";

interface productRatingProps {
    productRating: string
    setProductRating: (value: string) => void,
}


const ProductRating = ({productRating, setProductRating}:productRatingProps) => {
    return (
        <Styles>
            <p className="title">How satisfied are you with the products?</p>
            <img 
                src={PackageIcon}
                alt="food"
                height={100}
                className="avatar"
            />
            <p></p>
           <div className="stars">
            <div>
                <img 
                    onClick={()=> setProductRating('1')} 
                    src={(productRating === '1' || productRating === '2' || productRating === '3' || productRating === '4' || productRating === '5') ? StarIconFilled : StarIcon} 
                    alt="star" 
                    height={30} 
                    className="star"
                /><br/>
                <span>very bad</span>
            </div>
            <div>
                <img 
                    onClick={()=> setProductRating('2')} 
                    src={(productRating === '2' || productRating === '3' || productRating === '4' || productRating === '5') ? StarIconFilled : StarIcon} 
                    alt="star" 
                    height={30} 
                    className="star"
                />
            </div>
            <div>
                <img 
                    onClick={()=> setProductRating('3')} 
                    src={(productRating === '3' || productRating === '4' || productRating === '5') ? StarIconFilled : StarIcon} 
                    alt="star" 
                    height={30} 
                    className="star"
                />
            </div>
            <div>
                <img 
                    onClick={()=> setProductRating('4')} 
                    src={(productRating === '4' || productRating === '5') ? StarIconFilled : StarIcon} 
                    alt="star" 
                    height={30} 
                    className="star"
                />
            </div>
            <div>
                <img 
                    onClick={()=> setProductRating('5')} 
                    src={(productRating === '5') ? StarIconFilled : StarIcon} 
                    alt="star" 
                    height={30} 
                    className="star"
                /><br/>
                <span>very good</span>
            </div>
           </div>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 20px;

    .title {
        font-weight: bold;
    }

    .avatar {
        height: 100px;
        margin: 0 10px;
    }

    .stars {
        display: flex;
        justify-content: center;
        .star {
            margin: 10px;
            cursor: pointer;
        }
    }
`

export default ProductRating;