// show this when the driver and order is already rated
import styled from "styled-components";
import StarIconFilled from "../../assets/icons/star-orange.png";
import Avatar from "../../assets/icons/avatar.png";
import PackageIcon from "../../assets/icons/package-icon.png";

export default function Rated ({ driverName, driverRating, orderRating }:any){
    return (
        <Styles>
            <h3>Thank you for your rating and feedback.</h3>
            <div className="ratings-wrapper">
                {driverRating === "0.0" || driverRating === null ? null :
                    <div className="driver-rates">
                        <b>{driverName}</b><br/>
                        <img 
                            src={Avatar}
                            alt="driver"
                            height={50}
                            className="avatar"
                        /><br/>
                        <img  
                            src={StarIconFilled} 
                            alt="star" 
                            height={20} 
                            className="star"
                        /><span>{driverRating}</span>
                    </div>
                }
                {orderRating === "0.0" || orderRating === null ? null :
                    <div className="product-rates">
                        <b>Products</b><br/>
                        <img 
                            src={PackageIcon}
                            alt="food"
                            height={50}
                            className="package"
                        /><br/>
                        <img  
                            src={StarIconFilled} 
                            alt="star" 
                            height={20} 
                            className="star"
                        /> <span>{orderRating}</span>
                    </div>
                }
            </div>
            <a href="https://fleet.ke/">
            <div className="footer">
                Powered by Fleet
            </div>
            </a>

        </Styles>
    )
}

const Styles = styled.div`
    padding: 10%;
    .ratings-wrapper {
        display: flex;
        flex-direction: row;

        .driver-rates {
            box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
            border-radius: 5px;
            width: fit-content;
            padding: 20px 10%;
            margin: 10px;
            text-align: center;

            .avatar {
                border-radius: 50%;
            }
        }
        .product-rates {
            box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
            border-radius: 5px;
            width: fit-content;
            padding: 20px 10%;
            margin: 10px;
            text-align: center;

            .package {
                vertical-align: middle;
                margin: 10px;
            }
        }

        .star {
            vertical-align: middle;
            margin: 5px;
        }
    }
    .footer {
        position: fixed;
        bottom: 0;
        padding: 10px;
        background: #fefefe;
        width: 100%;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
    }
`