import styled from "styled-components";
import { TextArea, Button } from "../../components";

interface commentProps {
    setComment: (value: string) => void,
    submitReview: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"],
}

const Comments = ({setComment, submitReview}:commentProps) => {
    return (
        <Styles>
            <p className="title">Describe your experience</p>
            <TextArea
                placeholder="jot something... (Optional)"
                height="100px"
                width="90%"
                onChange={(e)=> setComment(e.target.value)}
            />
            <Button
                label="Submit review"
                onClick={submitReview}
                borderRadius="4px"
                background="#121744"
                border="none"
                padding="10px 20px"
                width="95%"
            />
        </Styles>
    )
}

const Styles = styled.div`
    padding: 20px;

    .title {
        font-weight: bold;
    }
`

export default Comments;