import styled from "styled-components";

interface navProps {
    step: number,
}

const OnboardingNavigation = ({ step }:navProps) => {
    return (
        <Styles>
            <div className="navigation">
                <div className="link">
                    <div className="circle" style={step === 1 ? {backgroundColor:'#f1c516', color: '#fff', borderColor:'#f1c516'}:{}}>1</div>
                    <div className="label" style={step === 1 ? {color:'#f1c516', fontWeight:'bold'}:{}}>Sign up</div>
                </div>
                <div className="link">
                    <div className="circle" style={step === 2 ? {backgroundColor:'#f1c516', color: '#fff', borderColor:'#f1c516'}:{}}>2</div>
                    <div className="label" style={step === 2 ? {color:'#f1c516', fontWeight:'bold'}:{}}>Name your organisation</div>
                </div>
                <div className="link">
                    <div className="circle" style={step === 3 ? {backgroundColor:'#f1c516', color: '#fff', borderColor:'#f1c516'}:{}}>3</div>
                    <div className="label" style={step === 3 ? {color:'#f1c516', fontWeight:'bold'}:{}}>Setup route defaults</div>
                </div>
                <div className="link">
                    <div className="circle" style={step === 4 ? {backgroundColor:'#f1c516', color: '#fff', borderColor:'#f1c516'}:{}}>4</div>
                    <div className="label" style={step === 4 ? {color:'#f1c516', fontWeight:'bold'}:{}}>Invite organisation members</div>
                </div>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    .navigation {
        padding: 15vh 20px;
        background-color: #121744;
        height: 100vh;

        .link {
            display: flex;
            flex-direction: row;
            margin: 30px 0;

            .circle {
                color: #ffffff;
                border: 1px #ffffff solid;
                padding: 5px 10px;
                border-radius: 50%;
                max-height: 20px;
            }
            
            .label {
                padding: 5px 10px;
                color: #ffffff;
            }
        }
    }
`

export default OnboardingNavigation;