import GlobalContextProvider from './context/GlobalContext';
import { Router } from "./routes"
import { FleetModal } from './components';
import { defaults } from 'chart.js';
defaults.global.defaultFontFamily = 'Lato';

function App() {
  return (
      <GlobalContextProvider>
        <Router/>
        <FleetModal/>
      </GlobalContextProvider>
  );
}

export default App;
