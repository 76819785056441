import React from "react";
import styled from "styled-components";

interface InputProps {
    type?: string,
    value: string,
    placeholder: string,
    onKeyDown?: any
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    width?: string,
    required: boolean
}

const Input = (props:InputProps) => {
    const { type, value, onKeyDown, onChange, placeholder, width, required } = props;
    return (
        <InputStyles
            type={type ? type : 'text'}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            width={width}
            onKeyUp={onKeyDown}
            required={required}
        />
    )
}

const InputStyles = styled.input`
    width: ${props => props.width ? props.width : '100%'};
    background-color: #fff;
    /* border: 1px #E6ECF1 solid; */
    border: 1px #505863 solid;
    padding: 12px 10px;
    border-radius: 5px;
    outline: none;
    margin: 10px 0;
    font-size: inherit;
    font-family: inherit;
    :focus {
        outline: 2px #121744 solid;
    }
`

export default Input;