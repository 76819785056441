
export const analyticsNavigationLinks = [
    {
        link:"/analytics/dispatch-sla",
        name: "Dispatch SLA"
    },
    {
        link:"/analytics/delivery-sla",
        name: "Delivery SLA"
    },
]

export const paymentsNavigationLinks = [
    {
        link:"/payments/driver-cost-management",
        name: "(3PL) Driver Cost Management"
    },
]