import styled from "styled-components";
import { AiOutlineCheckCircle } from 'react-icons/ai';

interface errorProps {
    successMessage: any,
}

const SuccessCard = ({ successMessage }:errorProps) => {
    return (
        <Styles>
            <AiOutlineCheckCircle fontSize={50} color="#a5dc86"/>
            <div>{successMessage}</div>
        </Styles>
    )
}

const Styles = styled.div`
    text-align: center;
`

export default SuccessCard;