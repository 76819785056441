import styled from "styled-components";
import { AiOutlineCloseCircle } from 'react-icons/ai';

interface modalProps {
    showModal: boolean,
    setShowModal: (value: boolean) => void,
    title: string,
    children: JSX.Element,
    marginTop?:string,
}

interface styleProps {
    marginTop?: string
}

const FleetModal = ({ showModal, setShowModal, title, children, marginTop }:modalProps) => {
    if(!showModal){
        return null;
    }
    return (
        <Styles marginTop={marginTop}>
            <div className="fleet-modal">
                <div className="fleet-modal-header">
                    <span>{title}</span>
                    <AiOutlineCloseCircle fontSize={20} onClick={()=> setShowModal(false)}/>
                </div>
                <div className="fleet-modal-content">
                    {children}
                </div>
            </div>
        </Styles>
    )
}

const Styles = styled.div<styleProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

  .fleet-modal {
    position: absolute;
    top: ${props => props.marginTop? props.marginTop : '40%'};
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    min-width: 300px;
    opacity: 1;
    z-index: 1000;
    border-radius: 5px;
    

    .fleet-modal-header {
        background: #121744;
        color: #ffffff;
        padding:10px;
        font-weight: bold;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        
        svg {
            vertical-align: middle;
            float: right;
            cursor: pointer;
        }
    }

    .fleet-modal-content {
        /* padding: 10px; */
    }
  }
`

export default FleetModal;