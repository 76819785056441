import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

const Tooltip = (props) => {
    const { label, toolTipInfo } = props;
    return (
        <Tippy 
            theme={'light'} 
            interactive={true} 
            content={<span style={{ fontWeight: "600" }}>{toolTipInfo}</span>}
        >
            <Styles>
                <img src={require('../../assets/icons/info.png')} alt="info" height={15} /> <span className='label'>{label}</span>
            </Styles>
        </Tippy>

    )
}

const Styles = styled.span`
    img {
        margin-left: 10px;
        vertical-align: middle;
    }
    .label {
        font-size: 10px;
        font-style: italic;
        cursor: pointer;
    }
`

export default Tooltip;