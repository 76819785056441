// driver cost analytics
import React, { useState } from "react";
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
import styled from "styled-components";
import { Bar, Line } from "react-chartjs-2";
import Pdf from 'react-to-pdf';
const ref = React.createRef();

const options = {
    responsive: true,
    legend: {
        position: 'bottom',
    },
};

const data = {
    labels: ['21 Jan 2023', '22 Jan 2023', '23 Jan 2023','24 Jan 2023', '25 Jan 2023', '26 Jan 2023', '27 Jan 2023' ],
    datasets: [
        {
            label: 'Logistics costs',
            data: [25000, 20000, 21000, 15000, 12000, 27000, 9000],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: '#ffced4',
        }
    ],
};



export default function DriverCostChartDaily (){
  const { dateRange } = React.useContext(GlobalContext);
  const [ showBarChart, setShowBarChart ] = useState(true);
    return (
        <Styles>
            <Pdf targetRef={ref} filename="logistics-cost.pdf">
                {({ toPdf }) => <button className="download" onClick={toPdf}>Download</button>}
            </Pdf>
            <p style={{color: '#0969da', cursor:'pointer' }} onClick={()=> setShowBarChart(!showBarChart)}>{showBarChart ? 'show line chart' : 'show bar chart'}</p>
            <div ref={ref}>
                <h3>Logistics costs (daily)</h3>
                {showBarChart ?
                    <Bar
                        options={options}
                        data={data}
                    /> :
                    <Line
                        options={options}
                        data={data}
                    />
                }
                <p>{moment(dateRange.startDate).format('LL')} to {moment(dateRange.endDate).format('LL')}</p>
            </div>
        </Styles>
    )   
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 1px #E6ECF1 solid;
    background-color: #ffffff;

    .download {
      float: right;
      background-color:transparent;
      color: #0173f6;
      border: none;
      outline: 0;
    }
`