import { useEffect, useState } from "react";
import styled from "styled-components";
import { FiPhoneCall } from 'react-icons/fi';
import { TfiArrowCircleLeft } from 'react-icons/tfi';
import Avatar from "../../assets/icons/avatar.png";
import { useParams, useHistory } from "react-router-dom";
import { trackingService } from "../../apis/tracking-service";
import { withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer, Marker, InfoWindow } from "react-google-maps";
import moment from "moment";
import { GiFamilyHouse } from 'react-icons/gi';

const mapStyles = [
  {
    featureType: "all",
    "stylers": [
      { "color": "#e1e1e1" }
    ]
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    "stylers": [
      { "color": "#545454" }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#545454",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    "stylers": [
      { "color": "#ffffff" }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#806b63",
      },
    ],
  },
  {
    "featureType": "landscape",
    "elementType": "labels",
    "stylers": [
      { "visibility": "off" }
    ]
  },

  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#93817c"
      }
    ]
  },
]

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const URL =`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

const GoogleMapsComponent = withScriptjs(
  withGoogleMap(({trackingInfo, setTrackingInfo, setDriverInfo}) => {
    const [result, setResult] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ driverCoordinates, setDriverCoordinates ] = useState(trackingInfo?.driver_location);
    const { id } = useParams();
    const orderId = id;

    const originMarker = new window.google.maps.MarkerImage(
      "https://cdn-icons-png.flaticon.com/128/2645/2645020.png",
      null, /* size is determined at runtime */
      null, /* origin is 0,0 */
      null, /* anchor is bottom center of the scaled image */
      new window.google.maps.Size(64, 64)
    );

    const destinationMarker = new window.google.maps.MarkerImage(
      "https://cdn-icons-png.flaticon.com/128/10365/10365034.png",
      null, /* size is determined at runtime */
      null, /* origin is 0,0 */
      null, /* anchor is bottom center of the scaled image */
      new window.google.maps.Size(42, 42),
    );

    // format coordinated from strings to int
    const formatCordinates = (a) => {
      if(a){
        a.lat = +a.lat;
        a.lng = +a.lng;
        return a;
      }
    };

    // check if driver in offline or not available
    let start;
    if((driverCoordinates?.lat === "" && driverCoordinates?.lng === "") || (trackingInfo?.driver_location.lat === "" && trackingInfo?.driver_location.lng === "")) {
        start = trackingInfo?.pickup_coords;
    } else {
      start = driverCoordinates ? driverCoordinates : trackingInfo?.driver_location;
    }

    const origin = formatCordinates(start);
    const destination = formatCordinates(trackingInfo?.drop_off_coords);

    useEffect(() => {
      trackingService.trackDriver({setLoading, orderId, setTrackingInfo});
    },[orderId, setTrackingInfo])

    // refetch diver location
    useEffect(() => {
      setInterval(() => {
        trackingService.refetchDriverLocation({ orderId, setDriverCoordinates })
      },5000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // refetch diver info
    useEffect(() => {
      setInterval(() => {
        trackingService.refetchDriverInfo({ orderId, setDriverInfo })
      },5000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      const directionService = new window.google.maps.DirectionsService();
      directionService.route(
        {
          origin: origin,
          destination: destination,
          waypoints: [],
          travelMode: window.google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setResult(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    },[orderId, setTrackingInfo, origin, destination]);

    if(loading){
      return <p>..</p>
    }

    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={origin}
        defaultOptions={{ styles: mapStyles }}
      >
        <Marker 
          icon={originMarker} 
          position={origin} 
        >
        </Marker>
        <Marker 
          icon={destinationMarker} 
          position={destination} 
        >
          <InfoWindow>
            <div>
              <b><GiFamilyHouse/></b><br/>
              <span>{trackingInfo?.order_details.dropoff.location.address}</span><br/><br/>
              <b>Expected time of arrival</b><br/>
              <span>{moment(trackingInfo?.order_details.ETA).format("dddd, MMMM Do YYYY, h:mm a")}</span>
            </div>
          </InfoWindow>
        </Marker>
        <DirectionsRenderer
          directions={result}
          options={{ suppressMarkers: true }}
        />
      </GoogleMap>
    );
  })
);

const DriverTrackingMap = props => {
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [ driverInfo, setDriverInfo ] = useState(trackingInfo?.driver);
  const history = useHistory();
  return (
    <MapPageStyles>
        <GoogleMapsComponent
            trackingInfo={trackingInfo}
            setTrackingInfo={setTrackingInfo}
            setDriverInfo={setDriverInfo}
            googleMapURL={URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
        <div className="bottom-sheet">
            <div className="back-button">
              <TfiArrowCircleLeft fontSize={30} style={{marginTop:'10px'}} onClick={history.goBack}/>
            </div>
            {window.screen.availWidth > 700 ? 
              <div>
                <span className="driver-info">
                  <b>{driverInfo?.name}</b><br/>
                  {driverInfo?.phone_number}
                </span>
              </div> : null
            }
            <div>
              <img src={driverInfo ? driverInfo?.thumbnail : Avatar} onError={(e)=> e.target.src = Avatar} alt="avatar" height={50} className="avatar"/>
            </div>
            <div>
              <a href={`tel:${driverInfo?.phone_number}`} className="telephone">
                  <button className="call-button"><FiPhoneCall/> <span>Call Driver</span></button>
              </a>
            </div>
        </div>
    </MapPageStyles>
  );
};

const MapPageStyles = styled.div`
  .bottom-sheet {
    position: absolute;
    margin-top: -13vh;
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px 0;
    background-color: rgb(18, 23, 68);
    color: #ffffff;
    border-radius: 12px;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(max-width: 1000px){
      margin-top: -30vh;
    }

    .back-button {
      margin: 0 10px
    }

    .avatar {
      border-radius: 50%;
      margin: 0 10px;
    }

    .call-button {
      background-color: rgb(241, 197, 22);
      border-radius: 4px;
      color: #121744;
      border: none;
      /* padding: 10px 20px; */
      font-size: 1.5rem;
      margin-top: 10px;
      margin-right: 10px;
      font-family: inherit;
      cursor: pointer;

      svg {
          vertical-align: middle;
          margin: 0 5px;
      }
      @media(max-width:600px){
        span {
          display: none;
        }
      }
  }

  }
`


export default DriverTrackingMap;