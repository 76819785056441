import { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { 
    DriverRating,
    ProductRating,
    Comments,
    RatingSuccess,
    SuccessCard
} from "../components";
import { ratingService } from "../apis/RatingService";
import { Rated } from "../components";

const RatingPage = () => {
    const [ driverRating, setDriverRating ] = useState('');
    const [ productRating, setProductRating ] = useState('');
    const [ comment, setComment ] = useState('');
    const [ response, setResponse ] = useState<any>(null);
    const { id }:any = useParams();
    const ratingInfo:any = useLocation().state;
    // const { doubleRating, driverName, driverRate, orderRate, isRated } = ratingInfo;

    const submitReview = () => {

        const public_id = id;
        const payload = {
            product_rating: parseInt(productRating),
            driver_rating: parseInt(driverRating),
            comment: comment,
        }
        ratingService.doubleRating({public_id, payload, setResponse});
    }

    if(ratingInfo?.isRated){
        return (
            <Rated
                doubleRating={ratingInfo?.doubleRating}
                driverName={ratingInfo?.driverName}
                driverRating={ratingInfo?.driverRate} 
                orderRating={ratingInfo?.orderRate}
            />
        )
    }

    if(response){
        return (
            <RatingSuccess>
                <SuccessCard successMessage={response?.data.message}/>
            </RatingSuccess>
        )
    }

    return (
        <Styles>
            <div className="centered">
                <h3>Help us improve our delivery service.</h3>
                <DriverRating
                    driverName={ratingInfo?.driverName}
                    driverRating={driverRating}
                    setDriverRating={setDriverRating}
                />
                {ratingInfo?.doubleRating ?
                    <ProductRating
                        productRating={productRating}
                        setProductRating={setProductRating}
                    /> : null
                }
                <Comments
                    setComment={setComment}
                    submitReview={submitReview}
                />
            </div>
            <a href="https://fleet.ke/">
            <div className="footer">
                Powered by Fleet
            </div>
            </a>
        </Styles>
    )
}

const Styles = styled.div`
    .centered {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        text-align: center;
        min-width: 320px;

        h3 {
            margin: 20px 0;
            font-size: 30px;
        }
    }

    .rated-centered {
        text-align: center;
        padding-top: 45%;
        border: 1px gray solid;
    }

    .footer {
        position: fixed;
        bottom: 0;
        padding: 10px;
        background: #fefefe;
        width: 100%;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
    }
`

export default RatingPage;