import React from "react";
import styled from "styled-components";

interface TextAreaProps {
    placeholder: string,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    width: string,
    height: string
}

interface StyleProps {
    width: string,
    height: string
}

const Input = (props:TextAreaProps) => {
    const { placeholder, width, height, onChange } = props;
    return (
        <InputStyles
            placeholder={placeholder}
            onChange={onChange}
            width={width}
            height={height}
        />
    )
}

const InputStyles = styled.textarea<StyleProps>`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '100px'};
    border: 1px #E6ECF1 solid;
    border-radius: 0px;
    padding: 10px;
    font-family: inherit;
    :focus {
        outline: 2px #121744 solid;
    }
`

export default Input;