import { useRef, useEffect, useCallback } from 'react';
import { TimepickerUI } from 'timepicker-ui';
import styled from 'styled-components';

interface timePickerProps {
  time: string,
  setTime: (value: string) => void,
}

const TimePicker = ({ time, setTime }:timePickerProps) => {
  const tmRef = useRef(null);

  const testHandler = useCallback((e: CustomEvent) => {
    setTime(`${e.detail.hour}:${e.detail.minutes} ${e.detail.type}`);
  }, [setTime]);

  useEffect(() => {
    const tm = (tmRef.current as unknown) as HTMLDivElement;

    const newPicker = new TimepickerUI(tm, {});
    newPicker.create();

    //@ts-ignore
    tm.addEventListener('accept', testHandler);

    return () => {
      //@ts-ignore
      tm.removeEventListener('accept', testHandler);
    };
  }, [testHandler]);

  return (
    <Styles>
        <div className='timepicker-ui' ref={tmRef}>
            <input
                type='test'
                className='timepicker-ui-input'
                defaultValue={time}
            />
        </div>
    </Styles>
  );
}

const Styles = styled.div`
    .timepicker-ui {
        input {
            padding: 12px;
            font-family: inherit;
            cursor: pointer;
            border: 1px #505863 solid;
            border-radius: 4px;
            font-size: 16px;
            margin: 10px 0;
        }
        :focus {
            outline: 2px #121744 solid;
        }
    }
`

export default TimePicker;