// routes defaults
import styled from "styled-components";
import { Input, Button } from "../../components";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import { TimePicker } from "../../components";
//@ts-ignore
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

interface routesProps {
    selectedStartRoutes: any,
    selectedFinishRoutes: any,
    startTime: string,
    finishTime: string,
    averageTimeAtStop:any,
    step: number,
    setSelectedStartRoutes: (value:any) => void,
    setSelectedFinishRoutes: (value:any)=> void,
    setStartTime: (value:any)=> void,
    setFinishTime: (value: string) => void,
    setAverageTimeAtStop: (value: any) => void,
    setStep: (value: number) => void;
}

const RoutesDefaults  = ({ selectedStartRoutes, setSelectedStartRoutes, selectedFinishRoutes, setSelectedFinishRoutes,  startTime, finishTime, averageTimeAtStop, setStartTime, setFinishTime, setAverageTimeAtStop, step, setStep }:routesProps) => {


    // submit and validations
    const handleSubmit = (event:any) => {
        event.preventDefault();
        if(selectedStartRoutes && selectedFinishRoutes && startTime && finishTime && averageTimeAtStop){
            setStep(step +1);
        }
    }

    return (
        <Styles>
             <form className="signup-form" onSubmit={handleSubmit}>
                <div className="signup-form-content">
                    <h2>Setup your route defaults</h2>
                    <p>Set the defaults for all drivers. You can edit or override these for individual drivers later.</p>
                    <div className="form">
                        <div className="form-section-one">
                            <label>Starting your routes</label><br/><br/>
                            <span className="sub-label">Start location <FaMapMarkerAlt/></span><br/>
                                <GooglePlacesAutocomplete
                                    apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                                    selectProps={{
                                        value: selectedStartRoutes,
                                        defaultValue: selectedStartRoutes.label,
                                        onChange: setSelectedStartRoutes,
                                        required: true
                                    }}
                                />
                        </div>
                        <div className="form-section-two">
                            <label></label><br/><br/>
                            <span className="sub-label">Start time <FiClock/></span>
                            <TimePicker
                                time={startTime}
                                setTime={setStartTime}
                            />
                        </div>
                    </div>
                    <div className="form">
                        <div className="form-section-one">
                            <label>Finishing your routes</label><br/><br/>
                            <span className="sub-label">End location <FaMapMarkerAlt/></span><br/>
                                <GooglePlacesAutocomplete
                                    apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                                    selectProps={{
                                        value: selectedFinishRoutes,
                                        defaultValue: selectedFinishRoutes.label,
                                        onChange: setSelectedFinishRoutes,
                                        required: true
                                    }}
                                />
                        </div>
                        <div className="form-section-two">
                            <label></label><br/><br/>
                            <span className="sub-label">End time <FiClock/></span>
                            <TimePicker
                                time={finishTime}
                                setTime={setFinishTime}
                            />
                        </div>
                    </div>
                    <div>
                        <label>Average time at stop</label><br/><br/>
                        <span className="sub-label">How long drivers stand still per stop. Used to optimise and calculate ETAs</span><br/>
                        <Input
                            type="number"
                            value={averageTimeAtStop.minutes}
                            placeholder="Minutes"
                            onChange={(e)=> setAverageTimeAtStop({ minutes: e.target.value, seconds: averageTimeAtStop.seconds })}
                            required
                            width="100px"
                        />
                        <span style={{ margin:'10px'}}></span>
                        <Input
                            type="number"
                            value={averageTimeAtStop.seconds}
                            placeholder="Seconds"
                            onChange={(e)=> setAverageTimeAtStop({ minutes: averageTimeAtStop.minutes, seconds: e.target.value })}
                            required
                            width="100px"
                        /><br/>
                    </div>
                </div>

                <div className="navigate">
                    <Button
                    onClick={()=> setStep(step -1)}
                        label="Previous"
                        width="fit-content"
                        borderRadius="5px"
                        background="transparent"
                        color="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                    <Button
                        type="submit"
                        label="Next"
                        width="fit-content"
                        borderRadius="5px"
                        background="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                </div>

            </form>
        </Styles>
    )
}

const Styles = styled.div`
.signup-form {
        padding: 0;
        margin: 0;
        
        .signup-form-content {
            padding: 5% 10%;

            h2 {
                color:#121744;
            }
            label {
                font-weight: bold;
            }
            .sub-label {
                color: #8d9cba;
            }
            .form {
                margin: 20px 0;
                display: flex;
                flex-direction: row;
                .form-section-one {
                    flex: 60%;
                }
                .form-section-two {
                    flex: 30%;
                }
                svg {
                    vertical-align: middle;
                }

                @media(max-width:1000px){
                    flex-direction: column;
                }
            }
            .input-drop-down {
                /* background-color: red; */
                background-color: #fff;
                /* padding: 10px; */
                width: fit-content;
                margin-top: -10px;
                position: absolute;
                border: 2px #121744 solid;
                border-radius: 4px;

                .input-drop-down-item {
                    cursor: pointer;
                    padding: 10px;

                    :hover {
                        background-color: #121744;
                        color: #fff;
                    }
                }
            }
        }
    }

    .navigate {
        bottom: 0;
        position: fixed;
        width: 70%;
        background-color: #f3f5f6;
        text-align: right;

        @media(max-width:1000px){
            width: 100%;
        }
    }
`
export default RoutesDefaults