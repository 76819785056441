import { useState } from "react";
import styled from "styled-components";
import FleetModal from "../../shared/fleet-modal";
import CreatePricingModel from "./create-pricing-model";
import Currencies from "./currencies";

const pricing = [
    {
        "company_name": "Swyft",
        "base_rate": "KES 100 per 0-4KM",
        "price_per_km": "KES 20 ",
        "vat": "16"
    },
    {
        "company_name": "Kosim",
        "base_rate": "KES 120 per 0-4KM",
        "price_per_km": "KES 25",
        "vat": ""
    },
    {
        "company_name": "Uber",
        "base_rate": "KES 95 per 0-4KM",
        "price_per_km": "KES 15",
        "vat": ""
    }
]

const PriceManagement = () => {
    const [ openPricingModal, setOpenPricingModal ] = useState(false);
    const [ openCurrencyModal, setOpenCurrencyModal ] = useState(false);
    return (
        <Styles>
            <div className="price-header">
                <h3>Price Management</h3>
                <span className="create-button" onClick={()=> setOpenPricingModal(true)}>Create pricing model</span>
                <span className="create-button" onClick={()=> setOpenCurrencyModal(true)}>Set currency</span>
            </div>
            <div className="price-card-holder">
                { pricing.map((item)=>(
                    <div className="" key={pricing.indexOf(item)}>
                        <div className="price-card">
                            <div>
                                <p><b>Company</b></p>
                                <p><b>Base Rate</b></p>
                                <p><b>Price per KM</b></p>
                                <p><b>Vehicle size lease</b></p>
                                <p><b>Per delivery</b></p>
                                <p><b>Per month lease</b></p>
                                <p><b>Per day lease</b></p>
                                <p><b>VAT</b></p>
                            </div>
                            <div>
                                <p>{item.company_name}</p>
                                <p>{item.base_rate}</p>
                                <p>{item.price_per_km}</p>
                                <p>{'not available!'}</p>
                                <p>{'not available!'}</p>
                                <p>{'not available!'}</p>
                                <p>{'not available!'}</p>
                                <p>{item.vat === ''? '16% inclusive' : `${item.vat} % exclusive`}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* modals */}
            <FleetModal showModal={openPricingModal} setShowModal={setOpenPricingModal} title="Pricing Model" marginTop="55%">
                <CreatePricingModel/>
            </FleetModal>
            <FleetModal showModal={openCurrencyModal} setShowModal={setOpenCurrencyModal} title="Currencies">
                <Currencies/>
            </FleetModal>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 1px #E6ECF1 solid;
    background-color: #ffffff;

    .price-header {
        padding: 10px 20px;
        .create-button {
            color: rgb(9, 105, 218);
            margin-top: 10px;
            margin-right: 20px;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .price-card-holder {
        display: flex;
        flex-wrap: wrap;
        >div {
            margin: 10px;
            padding: 10px;
            flex: 20%;
        }

        .price-card {
            padding: 10px 20px;
            margin: 10px 0;
            border: 1px #E6ECF1 solid;
            background-color: #ffffff;
            margin: 10px !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
`

export default PriceManagement;