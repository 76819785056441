import React,{ useContext } from 'react';
import styled from 'styled-components';
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
import Pdf from 'react-to-pdf';
const ref = React.createRef();

export default function SingleDriverCost (props) {
    const { driver } = props;
    const { dateRange, user } = useContext(GlobalContext);

    const getDays = (start, end) => {
        var a = moment(start);
        var b = moment(end);
        return b.diff(a, 'days') + 1
    }

    return (
        <Styles>
            <div className='slip-card' ref={ref}>
                <div className='logo-holder'>
                    <img src={user.logo} alt="logo" height={50}/> <b>{user.name}</b>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h3>Driver Payment Slip</h3>
                        -----------------------------------------------------
                    </div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Name:</b></div>
                    <div className='col-md-6'>{driver.name}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Driver Tag:</b></div>
                    <div className='col-md-6'>{driver.tag}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Orders Delivered:</b></div>
                    <div className='col-md-6'>{driver.orders_delivered}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Return Orders:</b></div>
                    <div className='col-md-6'>{driver.orders_failed}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Distance Covered:</b></div>
                    <div className='col-md-6'>{driver.kms_covered}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Duration:</b></div>
                    <div className='col-md-6'>{getDays(dateRange.startDate,dateRange.endDate)} Days</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Dates:</b></div>
                    <div className='col-md-6'>{moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')}</div>
                </div>
                <div>-------------------------------------------------------------</div>
                <div className='section row'>
                    <div className='col-md-6'><b>Total Cost:</b></div>
                    <div className='col-md-6'>{driver.total_cost}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Bonus:</b></div>
                    <div className='col-md-6'>{driver.bonus}</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Tax (VAT):</b></div>
                    <div className='col-md-6'>16% = {(0.16*(parseInt(driver.total_cost)))} KES</div>
                </div>
                <div>-------------------------------------------------------------</div>
                <div className='section row'>
                    <div className='col-md-6'><b>Total:</b></div>
                    <div className='col-md-6'>{(1.16*(parseInt(driver.total_cost))).toFixed(2)} KES</div>
                </div>
                <div className='section row'>
                    <div className='col-md-6'><b>Paid Amount:</b></div>
                    <div className='col-md-6'>{driver.paid_amount}</div>
                </div>
                <div>-------------------------------------------------------------</div>
                <div className='section row'>
                    <div className='col-md-6'><b>Unpaid Amount:</b></div>
                    <div className='col-md-6'>{((1.16*(parseInt(driver.total_cost))).toFixed(2)) - parseInt(driver.paid_amount)} KES</div>
                </div>
            </div>
            <Pdf targetRef={ref} filename={`${driver.name}.pdf`}>
                {({ toPdf }) => <button onClick={toPdf}><i className="fa fa-download"></i> Download slip</button>}
            </Pdf>
        </Styles>
    )   
}
const Styles = styled.div`
    padding: 10px;
    img {
        vertical-align: middle;
    }

    .slip-card {
        padding: 10px;
        margin: 20px;
        background-color: #f5f7fa;

        .logo-holder {
            padding: 10px;
        }

        .section {
            display: flex;
            justify-content: space-between;
            min-width: 500px;
            text-align: left;
            >div {
                margin: 10px;
                padding: 10px;
                flex: 40%;
            }
        }
    }
    button {
        float: right;
        background-color: #0173f6;
        color: #ffffff;
        border: none;
        outline: 0;
    }
`