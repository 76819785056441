import styled from 'styled-components';
import { Topbar, PaymentsNavigation } from "../components";

import DriverCostSummaryCards from '../components/payments/driver-cost-management/driver-cost-summary-cards';
import DriversTable from '../components/payments/driver-cost-management/drivers-table';
import PriceManagement from '../components/payments/driver-cost-management/price-management';
import DriverCostChartDaily from '../components/payments/driver-cost-management/driver-cost-chart-daily';
import DriverCostChartMonthly from '../components/payments/driver-cost-management/driver-cost-chart-monthly';

export default function DriverCostManagement () {
    return (
        <Page>
            <Topbar/>
            <PaymentsNavigation/>
            <DriverCostSummaryCards/>
            <DriversTable/>
            <PriceManagement/>
            <div className='wrapper'>
                <DriverCostChartDaily/>
                <DriverCostChartMonthly/>
            </div>
        </Page>
    )
}

const Page = styled.div`
    background-color: #f5f7fa;
    min-height: 100vh;

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        >div {
            margin: 10px;
            padding: 10px;
            flex: 40%;
        }
    }
`