import { useContext, useState } from "react";
import { GlobalContext } from '../../context/GlobalContext'
import styled from "styled-components";
import { formatDate } from '../../utils/dateRanger';
import { BsFillCalendarDateFill } from 'react-icons/bs';

//@ts-ignore
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const FleetCalendarPicker = () => {
    const [ showPicker, setShowPicker ] = useState(false);
    const { setDateRange } = useContext(GlobalContext);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const range = { startDate: formatDate(startDate), endDate: formatDate(endDate) };
    return (
        <Styles>
            { !showPicker ? <BsFillCalendarDateFill fontSize={20} onClick={()=>setShowPicker(true)} /> : null }
            { showPicker ?
                <div>
                    <DateRangePicker
                        startDate={startDate}
                        startDateId="tata-start-date"
                        endDate={endDate}
                        endDateId="tata-end-date"
                        onDatesChange={({ startDate, endDate }:any) => { setStartDate(startDate); setEndDate(endDate);}}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput:any) => setFocusedInput(focusedInput)}
                        isOutsideRange={() => false}
                        numberOfMonths={2}
                    />
                    {!(startDate && endDate) ? null : <button onClick={()=>{setDateRange(range); setShowPicker(false)}} className="date-filter-button">set</button> }
                </div> : null
            }
        </Styles>
    )
}

const Styles = styled.div`
    margin-right: 30px;

   .date-filter-button {
        position: absolute;
        margin-top: 5px;
        background-color:#121744;
        color: #ffffff;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
   }
`
export default FleetCalendarPicker;