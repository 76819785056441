// invoice pages
import { useState } from "react";
import styled from "styled-components";
import { Invoice, Button, SignaturePad, FleetModal, Input } from "../components";

export default function InvoicePage (){
    const [ showSignatureModal, setShowSignatureModal ] = useState(false);
    const [ showNameModal, setShowNameModal ] = useState(false);
    const [ url, setUrl ] = useState<any>()
    const [ name, setName ] = useState('')
    return (
        <Styles>
            <Invoice signature={url} name={name}/>
            <div className="invoice-actions">
                <Button
                    label=" click to add name"
                    width="fit-content"
                    onClick={()=> setShowNameModal(true)}
                />
                <Button
                    label=" click to sign the invoice"
                    width="fit-content"
                    onClick={()=> setShowSignatureModal(true)}
                />
            </div>
            <FleetModal title="Delivery Note" showModal={showNameModal} setShowModal={()=> setShowNameModal(false)}>
                <div style={{ textAlign:'center'}}>
                    <Input 
                        required 
                        type="text" 
                        placeholder="name" 
                        value={name} 
                        onChange={(e)=> setName(e.target.value)}
                        width="90%"
                    />
                    <Button
                        label="apply name to invoice"
                        width="fit-content"
                        onClick={()=> setShowNameModal(false)}
                    />
                </div>
            </FleetModal>
            <FleetModal title="Delivery Note  signature" showModal={showSignatureModal} setShowModal={()=> setShowSignatureModal(false)}>
                <div style={{ textAlign:'center'}}>
                    <SignaturePad url={url} setUrl={setUrl}/>
                    <Button
                        label="apply signature to delivery note"
                        width="fit-content"
                        onClick={()=> setShowSignatureModal(false)}
                    />
                </div>
            </FleetModal>
        </Styles>
    )
}

const Styles = styled.div`
    background-color: #f3f5f7;
    min-height: 100vh;
    padding: 20px;

    .invoice-actions {
        //
    }
`