import styled from 'styled-components';
import { 
    Topbar,
    AnalytcisNavigation,
    DispatchSlaEditor, 
} from '../../components';

import DispatchSlaByZone from '../../components/analytics/dispatch/DispatchSlaByZone';
import DispatchSlaByDate from '../../components/analytics/dispatch/DispatchSlaByDate';
import PerformanceByDispatcher from '../../components/analytics/dispatch/PerformanceByDispatcher';
import DispatchSlaByCustomerSegment from '../../components/analytics/dispatch/DispatchSlaByCustomer';
import DispatchMissedSlaByCustomer from '../../components/analytics/dispatch/DispatchMissedSlaByCustomer';
import DispatchSlaTable from '../../components/analytics/dispatch/DispatchSlaTable';
import FailedAndSuccessfulDispatchByZone from '../../components/analytics/dispatch/FailedDispatchByZone';
import FailedAndSuccessfulDispatchByDate from '../../components/analytics/dispatch/FailedDispatchByDate';

export default function DispatchSLA () {
    return (
        <Styles>
            <Topbar/>
            <AnalytcisNavigation/>
            <DispatchSlaEditor/>
            <div className='wrapper'>
                <DispatchSlaByZone/>
                <DispatchSlaByDate/>
                <PerformanceByDispatcher/>
                <DispatchSlaByCustomerSegment/>s
                <DispatchMissedSlaByCustomer/>
                <DispatchSlaTable/>
                <FailedAndSuccessfulDispatchByZone/>
                <FailedAndSuccessfulDispatchByDate/>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        >div {
            margin: 10px;
            padding: 10px;
            flex: 40%;
        }
    }
`