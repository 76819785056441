import React from "react";
import styled from "styled-components";

interface ButtonProps {
    type?: "submit" | "button" | "reset",
    label: any,
    showIcon?: boolean,
    iconUrl?: string,
    background?: string,
    color?: string
    border?: string,
    padding?: string,
    margin?: string,
    borderRadius?: string,
    width?: string,
    disabled?: boolean
    onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"],
}

const Button = (props:ButtonProps) => {
    const { 
        type,
        label,
        showIcon,
        iconUrl,
        background,
        color,
        border,
        padding,
        margin,
        borderRadius,
        width,
        disabled,
        onClick,
    } = props;
    return (
        <Styles
            type={type}
            label={label}
            background={background ? background : '#121744'}
            color={color ? color : '#ffffff'}
            border={border ? border : '1px #121744 solid'}
            padding={padding ? padding : '10px 20px'}
            margin={margin ? margin : '10px 0'}
            borderRadius={borderRadius ? borderRadius : '5px'}
            width={width ? width : '100%'}
            disabled={disabled}
            onClick={onClick}
        >
            {showIcon ? <img src={iconUrl} alt="icon" height={20} width={20}/> : null}
            {label}
        </Styles>
    )
}

const Styles = styled.button <ButtonProps>`
    background: ${props => props.background};
    color: ${props => props.color};
    border: ${props => props.border};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    border-radius: ${props => props.borderRadius};
    width: ${props => props.width};
    font-weight: 700;
    font-size: inherit;
    font-family: inherit;
    img, svg {
        margin: 0 5px;
        vertical-align: bottom;
    }
    &:disabled {
        cursor: not-allowed;
        background: linear-gradient(0deg, rgba(154, 159, 191, 0.5), rgba(154, 159, 191, 0.5)), #FFFFFF;
        border: none;
    }
    &:focus {
        outline: none;
        cursor: pointer;
    }
    &:hover {
        opacity: 0.9;
    }
`

export default Button;