import React from "react";
import moment from "moment";
import { GlobalContext } from '../../../context/GlobalContext';
import styled from "styled-components";
import ReactApexChart from 'react-apexcharts';
import { FiDownload } from 'react-icons/fi';
import Pdf from 'react-to-pdf';
const ref = React.createRef();
const options = { orientation: 'landscape' };

const state = {
          
    series: [10, 10, 30],
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      },
      colors: ['#bbbaff', '#7779ff', '#f5c214'],
      labels: ['1st Time Customer', '2nd Time Customer', '3rd Time Customer'],
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
}

export default function DispatchMissedSlaByCustomer (){
  const { dateRange } = React.useContext(GlobalContext);
    return (
        <Styles>
          <Pdf targetRef={ref} options={options} filename="dispatch-missed-sla-by-customer.pdf">
            {({ toPdf }) => <button onClick={toPdf}><FiDownload/></button>}
          </Pdf>
          <div ref={ref}>
            <h3>Missed sla by customer (Dispatch)</h3>
            <ReactApexChart
                options={state.options} 
                series={state.series} 
                type="donut" 
                height={390} 
            />
             <p>{moment(dateRange.startDate).format('LL')} to {moment(dateRange.endDate).format('LL')}</p>
          </div>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;
    margin: 10px;
    border: 2px #E6ECF1 solid;
    border-radius: 6px;

    button {
      float: right;
      background-color: #0173f6;
      color: #ffffff;
      border: none;
      outline: 0;
      cursor: pointer;
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }
`