import { useContext } from "react";
import { GlobalContext } from '../../../context/GlobalContext'
import styled from "styled-components";

const hours = [ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

export default function DeliverySlaEditor (){
    const { currentDeliverySla, setCurrentDeliverySla } = useContext(GlobalContext);
    return (
        <Styles>
            <span>
                Current target delivery SLA: 
                <Select>
                    <select onChange={(e)=> setCurrentDeliverySla(e.target.value)}>
                        <option defaultValue={currentDeliverySla}>{currentDeliverySla}</option>
                        {hours?.map((hour)=> (
                            <option value={hour} key={hours.indexOf(hour)}>
                                {hour}
                            </option>
                        ))}
                    </select>
                </Select> hrs
            </span>
            <span>63% <b>SLA Met</b></span>
            <span>37% <b>SLA Missed</b></span>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;
    text-align: center;
    span {
        margin: 0 10px;
    }
`
const Select = styled.span`
    select {
        background-color: #52586c;
        color: #fff;
        border:none;
        outline: none;
        cursor: pointer;
    }
`