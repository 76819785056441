import { useState } from "react";
import styled from "styled-components";
import { ErrorCard, SuccessCard, Button } from "../../components";
import { objectToArray } from "../../utils/utilities";
import { authService } from "../../apis/authService";
import { BsInfoCircle } from 'react-icons/bs';

interface confirmProps {
    data:any
    setShowConfirmationModal:(value:boolean) => void,
}

export default function OnboardingConfirm ({ data, setShowConfirmationModal }:confirmProps){
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<any>(null);
    const [ response, setResponse ] = useState<any>(null);

    const submitOnboardingData = () => {
        if(data){
            const signupPayload = {
                name: data.name,
                email:data.email,
                password: data.password,
                country: data.country,
                time_zone: data.time_zone,
                organisation_name: data.organisation_name,
                organization_type: data.organization_type,
                organization_team_size: data.organization_team_size,
                timezone: data.timezone,
                start_location: data.start_location,
                start_time: data.start_time,
                end_location: data.end_location,
                end_time: data.end_time,
                driver_time_per_stop: data.driver_time_per_stop,
                team_members: data.team_members,
            }
            
            // submit data
            authService.signup({ signupPayload, setLoading, setError, setResponse })
        }
    }

    return (
        <Styles>
            {   error ? 
                <ErrorCard 
                    errorMessage={
                    <div style={{ textAlign:'left', color:'red', padding:'10px 20px' }}>
                        {objectToArray(error)?.map((item:any) => (
                            <p>{item}*</p>
                        ))}
                    </div>
                    }
                /> 
                : response ? 
                <SuccessCard 
                    successMessage={
                        <div style={{ padding:'10px 20px' }}>
                            <b>Success!</b>
                        </div>
                    }
                /> : 
                <div>
                    <BsInfoCircle fontSize={50} color="#2196f3"/>
                    <p>Submit and get started with your 14-day trial.</p>
                    <Button
                        label="Cancel"
                        onClick={()=> setShowConfirmationModal(false)}
                        width="fit-content"
                        borderRadius="5px"
                        background="red"
                        border="1px red"
                        padding="10px 20px"
                        margin="10px"
                    />
                    <Button
                        label={loading ? "processing..." : "Start trial"}
                        onClick={submitOnboardingData}
                        width="fit-content"
                        borderRadius="5px"
                        background="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                </div>
            }
        </Styles>
    )
}

const Styles = styled.div`
    text-align: center;
    padding: 10px 20px;
`