import styled from "styled-components";
import StarIcon from "../../assets/icons/star.png";
import StarIconFilled from "../../assets/icons/star-orange.png";
import Avatar from "../../assets/icons/avatar.png";

interface driverRatingProps {
    driverName: any,
    driverRating: string,
    setDriverRating: (value: string) => void,
}

const DriverRating = ({driverName, driverRating, setDriverRating}:driverRatingProps) => {
    return (
        <Styles>
            <p className="title">How would you rate the service of the courier?</p>
            <img 
                src={Avatar}
                alt="driver"
                height={100}
                className="avatar"
            />
            <p><b>{driverName}</b></p>
            <div className="stars">
                <div>
                    <img 
                        onClick={()=> setDriverRating('1')}
                        src={(driverRating === '1' || driverRating === '2' || driverRating === '3' || driverRating === '4' || driverRating === '5') ? StarIconFilled : StarIcon} 
                        alt="star" 
                        height={30} 
                        className="star"
                    /><br/>
                    <span>very bad</span>
                </div>
                <div>
                    <img 
                        onClick={()=> setDriverRating('2')} 
                        src={(driverRating === '2' || driverRating === '3' || driverRating === '4' || driverRating === '5') ? StarIconFilled : StarIcon} 
                        alt="star" 
                        height={30} 
                        className="star"
                    />
                </div>
                <div>
                    <img 
                        onClick={()=> setDriverRating('3')} 
                        src={(driverRating === '3' || driverRating === '4' || driverRating === '5') ? StarIconFilled : StarIcon} 
                        alt="star" 
                        height={30} 
                        className="star"
                    />
                </div>
                <div>
                    <img 
                        onClick={()=> setDriverRating('4')} 
                        src={(driverRating === '4' ||driverRating === '5') ? StarIconFilled : StarIcon} 
                        alt="star" 
                        height={30} 
                        className="star"
                    />
                </div>
                <div>
                    <img 
                        onClick={()=> setDriverRating('5')} 
                        src={(driverRating === '5') ? StarIconFilled : StarIcon} 
                        alt="star" 
                        height={30} 
                        className="star"
                    /><br/>
                    <span>very good</span>
                </div>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 20px;

    .title {
        font-weight: bold;
    }
    
    .avatar {
        border-radius: 50%;
        height: 100px;
        width: 100px;
        margin: 0 10px;
    }

    .stars {
        display: flex;
        justify-content: center;
        .star {
            margin: 10px;
            cursor: pointer;
        }
    }
`

export default DriverRating;