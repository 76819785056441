// signature pad
import { useState } from "react";
import styled from "styled-components";
import SignatureCanvas from 'react-signature-canvas';

export default function SignaturePad ({ setUrl }:any) {
    const [sign,setSign] = useState<any>()

    const handleClear= () =>{
        sign.clear()
        setUrl('')
    }
    const handleGenerate= () =>{
        setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))
    }
    return (
        <Styles>
            <div className="sign-canvas">
                <SignatureCanvas 
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                    ref={data=>setSign(data)}
                />
            </div>
            <div className="sign-buttons">
                <button style={{height:"30px",width:"60px"}} onClick={handleClear}>Clear</button>
                <button style={{height:"30px",width:"60px", marginLeft: '10px'}} onClick={handleGenerate}>Save</button>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    width: 100%;
    padding: 20px;
    .sign-canvas {
        border: 1px solid #dce0e7;
        background-color: #f3f5f7;
        width: calc(100% - 40px);
        height: 100px;

        @media (max-width: 700px){
            width: calc(90vw - 40px);
        }
    }
    .sign-buttons {
        margin-top: 10px;
    }
`