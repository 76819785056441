// team invites
import { useState } from "react";
import styled from "styled-components";
import { Input, Button, FleetModal, OnboardingConfirm } from "../../components";
import { AiOutlineUser } from 'react-icons/ai';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';


const roles = ['Driver', 'Dispatcher', 'Administrator'];

interface inviteProps {
    teamMembers: any,
    step: number,
    signupPayload: any,
    setTeamMembers: (value: any) => void,
    setStep: (value: number) => void;
}
const TeamInvites  = ({ teamMembers, setTeamMembers, step, setStep, signupPayload }:inviteProps) => {
    const [ inviteName, setInviteName ] = useState('');
    const [ inviteEmail, setInviteEmail ] = useState('');
    const [ selectedRole, setSelectedRole ] = useState('');
    
    const [ validations, setValidations ] = useState(false);
    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);

    // add a member
    // submit and validations
    const handleSubmitAddMember = (event:any) => {
        event.preventDefault();
        setValidations(true);
        if(inviteEmail){
            const payload = {
                name: inviteName,
                email_or_phone: inviteEmail,
                role: selectedRole
            }
            setTeamMembers([...teamMembers, payload])
            setInviteName('')
            setInviteEmail('')
            // setSelectedRole('')
        }
        
    }

    return (
        <Styles>
            <div className="signup-form">
                <div className="signup-form-content">
                <h2>Invite organisation members</h2>
                <form className="invites-form" onSubmit={handleSubmitAddMember}>
                    <div className="invite-role">
                        <label>Role</label><br/>
                        <Select>
                            <select onChange={(e)=> setSelectedRole(e.target.value)} required>
                                <option value={selectedRole}>{selectedRole ? selectedRole : 'Select'}</option>
                                {roles?.map((role)=> (
                                    <option value={role} key={roles.indexOf(role)}>
                                        {role}
                                    </option>
                                ))}
                            </select>
                        </Select>
                    </div>
                    <div className="invite-name">
                        <label>Name</label>
                        <Input
                            value={inviteName}
                            placeholder=''
                            onChange={(e)=> setInviteName(e.target.value)}
                            required
                            width="90%"
                        />
                    </div>
                    <div className="invite-email">
                        <label>{selectedRole === "Driver" ? "Phone" : "Email"}</label>
                        {selectedRole === "Driver" ? 
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={inviteEmail}
                                onChange={(value:any)=> setInviteEmail(value)}
                            /> :
                            <Input
                                type="email"
                                value={inviteEmail}
                                placeholder=""
                                onChange={(e)=> setInviteEmail(e.target.value)}
                                required
                                width="90%"
                            />
                        }
                        {(validations &&selectedRole === "Driver" && !inviteEmail) ? <div className="error-message">Please add phone number*</div> : null}
                    </div>
                    <div className="invite-submit">
                        <br/>
                        <Button
                            type="submit"
                            label="Add"
                            width="fit-content"
                            borderRadius="5px"
                            border="none"
                            padding="14px 20px"
                            margin="10px"
                        />
                    </div>
                </form>
                {teamMembers?.length < 1 ? null :
                    <div className="invite-table">
                        <h3>Team members</h3>
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Email / Phone</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                { teamMembers?.map((member:any) => (
                                        <tr key={teamMembers?.indexOf(member)} >
                                            <td><AiOutlineUser/></td>
                                            <td>{member.name}</td>
                                            <td>{member.email_or_phone}</td>
                                            <td>{member.role}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                }
                </div>
                <div className="navigate">
                    <Button
                    onClick={()=> setStep(step -1)}
                        label="Previous"
                        width="fit-content"
                        borderRadius="5px"
                        background="transparent"
                        color="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                    <Button
                        onClick={()=> setShowConfirmationModal(true)}
                        label="Start Trial"
                        width="fit-content"
                        borderRadius="5px"
                        background="#121744"
                        border="1px #121744 solid"
                        padding="10px 20px"
                        margin="10px"
                    />
                </div>
            </div>
            <FleetModal showModal={showConfirmationModal} setShowModal={()=> setShowConfirmationModal(false)} title="Onboarding">
                <OnboardingConfirm
                    data={signupPayload}
                    setShowConfirmationModal={setShowConfirmationModal}
                />
            </FleetModal>
        </Styles>
    )
}

const Styles = styled.div`
    .signup-form {
        padding: 0;
        margin: 0;
        
        .signup-form-content {
            padding: 5% 10%;
            h2, h3 {
                color:#121744;
            }
            .invites-form {
                margin-top: 10%;
                display: flex;
                flex-direction: row;
                @media(max-width:1000px){
                    flex-direction: column;
                }

                .invite-role {
                    flex: 20%;
                    padding: 0 10px;
                }
                .invite-name {
                    flex: 30%;
                    padding: 0 10px;
                }
                .invite-email {
                    flex: 30%;
                    padding: 0 10px;
                }
                .invite-submit {
                    flex: 20%;
                }
                label {
                    font-weight: bold;
                }
                .error-message {
                    color: red;
                    font-size: 10px;
                    font-style: italic;
                }
            }
        }

        .navigate {
            bottom: 0;
            position: fixed;
            width: 70%;
            background-color: #f3f5f6;
            text-align: right;

            @media(max-width:1000px){
                width: 100%;
            }
        }
    }
`
const Select = styled.span`
    select {
        border: 1px #505863 solid;
        border-radius: 5px;
        padding: 12px 10px;
        margin: 10px 0;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        /* color: #E6ECF1; */
        width: 100%;

        option {
            span {
                margin: .3rem;
            }
        }
        :focus {
            outline: 2px #121744 solid;
        }
    }
`

const Table = styled.table`
    text-align: left;
    border-collapse: collapse;
    overflow-x: scroll;
    width:100%;

    img, svg { vertical-align: middle; }

    th { 
        font-weight: bold;
        cursor: pointer;

        .fa {
            font-size: 10px;
        }
    }

    th, td {
        padding: 1rem .8rem;
        border-bottom: 1px #f0f0f0 solid;
    }

    .active-row {
        background-color: #f0f0f0;
    }

    tbody {
        tr:nth-child(even){
            background-color: #f2f2f2;
        }
        tr:hover {
            background-color: #f2f2f2;
            cursor: pointer;
        }
    }
`

export default TeamInvites;