import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { analyticsNavigationLinks } from "../../routes/navigation-links";
import FleetCalendarPicker from "../shared/fleet-calendar-picker";

const AnalyticsNavigation = () => {
    return (
        <Styles>
            <section className="nav-links">
                {analyticsNavigationLinks.map((route)=> (
                    <span key={analyticsNavigationLinks.indexOf(route)}>
                        <NavLink to={`${route.link}`} className='link' activeClassName="active">{route.name}</NavLink>
                    </span>
                ))}
            </section>
            <section>
                <FleetCalendarPicker/>
            </section>
        </Styles>
    )
}

const Styles = styled.div`
    border-bottom: 1px #E6ECF1 solid;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .nav-links {
        .link {
            padding: 9px 0 ;
            margin: 0 10px;
            text-decoration: none;
            color: inherit;
            font-size: 14px;
        }

        .active {
            border-bottom: 3px #F1C516 solid;
        }
    }
`
export default AnalyticsNavigation;