// tracking
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { trackingService } from "../../apis/tracking-service";
import { trackDriverInfoProps } from "../../types";
import { FiPhoneCall } from 'react-icons/fi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';
import moment from "moment";
import { InfoCard } from "../../components";


const Tracking = () => {
    const [trackingInfo, setTrackingInfo] = useState<trackDriverInfoProps>();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<any>(null);
    const history = useHistory();
    const { id }:any = useParams();
    const orderId = id;
    //@ts-ignore
    const orderState = trackingInfo?.order_state[0].state;
    //@ts-ignore
    const orderStateId = trackingInfo?.order_state[0].id;
    //@ts-ignore
    const arrivalTime = trackingInfo?.order_details.ETA;

    const ratingInfo = {
        doubleRating: trackingInfo?.double_rating,
        driverRate: trackingInfo?.driver.rating,
        orderRate: trackingInfo?.order_details.order_rating,
        isRated: trackingInfo?.is_rated,
        driverName: trackingInfo?.driver.name
    }
    
    useEffect(()=> {
        setInterval(() => {
            trackingService.trackDriver({setLoading, setError, orderId, setTrackingInfo})
        },5000);
    },[orderId])

    useEffect(()=> {
        if(orderState === 7){
            setTimeout(()=> {
                history.push({ pathname: `/rating/${orderStateId}`, state: ratingInfo})
            },1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[orderState])

    const goToMap = () => {
        history.push({ pathname: `/tracking-map/${orderId}`})
    }

    if(loading){
        console.log('loading...')
    }

    if(error && error) {
        return <div style={{paddingLeft:'20%'}}><InfoCard infoMessage={error}/></div>
    }

    return (
        <Styles>
            <div className="top-bar">
                {arrivalTime ? 
                    <p><b>Estimated time of arrival</b> <br/>{moment(arrivalTime).format("dddd, MMMM Do YYYY, h:mm a")}</p>
                    : error ? <p style={{ color:'red' }}>error!</p> : <p>loading...</p>
                }
            </div>
            <div className="verticle-progress-bar">
                {/* section one */}
                <div className="section">
                    <div className="section-upper">
                        <div className="circle" style={(orderState === 1 || orderState === 2 || orderState === 3) ? { borderColor:'#F1C516', backgroundColor: '#F1C516'} : (orderState === 4 || orderState === 5 || orderState === 6 || orderState === 7 || orderState === 8 || orderState === 9) ? { backgroundColor:'#a1a1a1'}:{}}></div>
                        <span className="title" style={(orderState === 1 || orderState === 2 || orderState === 3) ? { color:'#505863' } : {}}>Dispatched</span>
                    </div>
                    <div className="section-lower" style={(orderState === 1 || orderState === 2 || orderState === 3) ? { borderLeft:'2px #F1C516 solid' } : {}}>
                        { 
                            (orderState === 1 || orderState === 2) ? 
                                <span>Hi, your order has been received and is being prepared for dispatch.</span>
                            : (orderState === 3) ? 
                                <span>
                                    Hi, your order is being dispatched today and  your expected arrival time - ETA is  <b>{moment(trackingInfo?.order_details.ETA).format("dddd, MMMM Do YYYY, h:mm a")}</b> <br/>
                                    Your order is number <b>{trackingInfo?.order_details.job_number.replace("/", " of ")}</b> orders.
                                </span>
                            : (orderState === 4 || orderState === 5 || orderState === 6 || orderState === 7 || orderState === 8 || orderState === 9) ? 
                                <span style={{color:'#a1a1a1'}}>
                                   <BsFillCheckCircleFill color="#a1a1a1"/> Complete
                                </span>
                            : null
                        }
                    </div> 
                </div>

                {/* section two */}
                <div className="section">
                    <div className="section-upper">
                        <div className="circle" style={(orderState === 4 || orderState === 5) ? { borderColor:'#F1C516', backgroundColor: '#F1C516'} : (orderState === 6 || orderState === 7 || orderState === 8 || orderState === 9) ? { backgroundColor:'#a1a1a1'} : {}}>
                        </div><span className="title" style={(orderState === 4 || orderState === 5) ? { color:'#505863' } : {}}>In transit</span>
                    </div>
                    <div className="section-lower" style={(orderState === 5) ? { borderLeft:'2px #F1C516 solid' } : {}}>
                        { 
                            (orderState === 4) ? 
                                <span>
                                    Hi, get ready to receive your order, driver <b>{trackingInfo?.driver.name}</b> (<a href={`tel:${trackingInfo?.driver.phone_number}`} className="telephone">{trackingInfo?.driver.phone_number}</a>) is on the way. <br/>
                                    Expected arrival time is  <b>{moment(arrivalTime).format("dddd, MMMM Do YYYY, h:mm a")}</b> <br/>
                                    <span>
                                        <a href={`tel:${trackingInfo?.driver.phone_number}`} className="telephone"><button className="call-button"><FiPhoneCall/> Call Driver</button></a>
                                        <button onClick={goToMap} className="call-button"> <FaMapMarkerAlt/> Track driver on map</button>
                                    </span>
                                </span>
                            : (orderState === 5) ? 
                                <span>
                                   Driver <b>{trackingInfo?.driver.name}</b> (<a href={`tel:${trackingInfo?.driver.phone_number}`} className="telephone">{trackingInfo?.driver.phone_number}</a>) has arrived
                                </span>
                            : (orderState === 6 || orderState === 7 || orderState === 8 || orderState === 9) ? 
                                <span style={{color:'#a1a1a1'}}>
                                    <BsFillCheckCircleFill color="#a1a1a1"/> Complete
                                </span>
                            : null
                        }
                    </div> 
                </div>

                {/* section three */}
                <div className="section">
                    <div className="section-upper">
                        <div className="circle" style={(orderState === 6 || orderState === 7 || orderState === 8 || orderState === 9) ? { borderColor:'#F1C516', backgroundColor: '#F1C516'} : {}}>
                        </div><span className="title" style={(orderState === 6 || orderState === 7 || orderState === 8 || orderState === 9) ? { color:'#505863' } : {}}>Delivered</span>
                    </div>
                    <div className="section-lower" style={{borderLeft:'none'}}>
                        { 
                            (orderState === 6) ? 
                                <span>
                                    <VscError color="red"/> Driver failed order <br/> 
                                    <i style={{ fontSize:'10px' }}>Reason: "{trackingInfo?.order_details.failure_reason}"</i>
                                </span>
                            : (orderState === 7) ? 
                                <span>
                                    <BsFillCheckCircleFill color="#78cd68"/> Order delivered
                                </span>
                            : (orderState === 8 || orderState === 9) ? 
                                <span>
                                    <AiOutlineInfoCircle color="#fabf41"/> Your order was returned back. 
                                </span>
                            : null
                        }
                    </div> 
                </div>

            </div>
        </Styles>
    )
}

const Styles = styled.div`
    .top-bar {
        padding: 10px 20px;
        font-size: 30px;

        @media (max-width:400px){
            font-size: 18px;
        }
    }

    .verticle-progress-bar {
        margin-left: 10%;
    }

    .section {
        padding-left: 20px;

        .section-upper {
            display: flex;
            padding: 5px 0;

            .circle {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                margin-left: -9px;
                border: 2px solid #a1a1a1;
                background-color: #ffffff;
                margin-top: 2px !important;
            }

            .title {
                margin-left: 10px;
                font-weight: 700;
                color: #a1a1a1;
                /* margin-top: -2px !important; */
            }
        }

        .section-lower {
            padding: 20px 15px;
            border-left: 2px #a1a1a1 solid;

            svg {
                vertical-align: middle;
            }

            .date {
                font-size: 12px;
                font-weight: lighter,
            }

            .telephone {
                text-decoration: underline;
                cursor: pointer;
            }

            .call-button {
                background-color:#121744;
                color: #ffffff;
                border: none;
                border-radius: 5px;
                padding: 10px 20px;
                margin-top: 10px;
                margin-right: 10px;
                font-family: inherit;
                cursor: pointer;

                svg {
                    vertical-align: middle;
                    margin: 0 5px;
                }
            }
        }
    }
`

export default Tracking;