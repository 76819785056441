// tracking service
import axios from "axios";
import { trackDriverProps } from "../types";

export const trackingService = {
    trackDriver: async ({ setLoading, setError, orderId, setTrackingInfo }:trackDriverProps) =>{
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/driver/driver_location_by_tracking_code/${orderId}`);
            setLoading(false);
            setTrackingInfo(response.data)
        } catch(error:any) {
            setLoading(false);
            setError(error.response.data.error ? error.response.data.error : error.message)
            throw error
        }
    },
    refetchDriverLocation: async ({ orderId, setDriverCoordinates }:any) =>{
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/driver/driver_location_by_tracking_code/${orderId}`);
            setDriverCoordinates(response.data.driver_location)
        } catch(error:any) {
            throw error
        }
    },
    refetchDriverInfo: async ({ orderId, setDriverInfo }:any) =>{
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/driver/driver_location_by_tracking_code/${orderId}`);
            setDriverInfo(response.data.driver)
        } catch(error:any) {
            throw error
        }
    }
}