import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { 
    OnboardingPage,
    DispatchSLA, 
    DeliverySLA, 
    DriverCostManagement, 
    TrackingPage,
    RatingPage,
    InvoicePage 
} from '../pages';
import { DriverTrackingMap } from '../components';

export default function Router () {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/onboarding' component={OnboardingPage} />
                <Route exact path='/analytics/dispatch-sla' component={DispatchSLA} />
                <Route exact path='/analytics/delivery-sla' component={DeliverySLA} />
                <Route exact path='/payments/driver-cost-management' component={DriverCostManagement} />
                <Route exact path="/tracking/:id" component={TrackingPage} />
                <Route exact path="/tracking-map/:id" component={DriverTrackingMap} />
                <Route exact path='/rating/:id' component={RatingPage} />
                <Route exact path='/invoice' component={InvoicePage} />
                <Redirect to="/onboarding" />
            </Switch>
        </BrowserRouter>
    )
}