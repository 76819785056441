// invoice
import styled from "styled-components";

const invoiceData = [
    {
        item:'olive oil',
        quantity_ordered: 5,
        quantity_received: 2
    },
    {
        item:'cheese',
        quantity_ordered: 5,
        quantity_received: 5
    },
]

interface invoiceProps {
    signature: string,
    name: string
}

export default function Invoice ({signature, name}:invoiceProps){
    return (
        <Styles>
            <h1>Delivery Note</h1>
            <div className="top">
                <div className="header">
                    <section className="header-section">
                        <p>Customer Name:</p>
                        <p>Customer Address:</p>
                        <p>Order Ref:</p>
                        <p>Order ID:</p>
                        <p>Delivery Date:</p>
                    </section>
                    <section className="header-section">
                        <p><b>Peter Kimani</b></p>
                        <p><b>2001, Nairobi</b></p>
                        <p><b>#3243232</b></p>
                        <p><b>#3253223</b></p>
                        <p><b>30 - 5 - 2023</b></p>
                    </section>
                </div>
                <div className="company-logo">
                    <img 
                        src="https://www.food4life.org.uk/multisite/wp-content/themes/websiteni-joints-foodforlife/assets/images/header/companylogo.svg"
                        alt=""
                        height={50}
                    />
                </div>
            </div>
            <div className="invoice-table">
                <Table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Product Name</th>
                            <th>Quantity Ordered </th>
                            <th>Quantity Received</th>
                        </tr>
                    </thead>
                    <tbody>
                        { invoiceData.map((item) => (
                                <tr key={invoiceData.indexOf(item)} >
                                    <td>{invoiceData.indexOf(item)+1}</td>
                                    <td>{item.item}</td>
                                    <td>{item.quantity_ordered}</td>
                                    <td>{item.quantity_received}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="invoice-signitory">
                <h3>Recipient Name:</h3>
                    <div style={{ paddingTop:'36px' }}>
                        {name}
                    </div>
                <div className="signature-line"></div>
            </div>
            <div className="invoice-signitory">
                <h3>Recipient Signature:</h3>
                    <img 
                        src={signature} 
                        alt=""
                        height={50} 
                    />
                <div className="signature-line"></div>
            </div>
            </div>
        </Styles>
    )
}
const Styles = styled.div`
    min-width: 600px;
    background-color: #ffffff;
    width: fit-content;
    padding: 5%;
    border: 1px solid #dce0e7;
    border-radius: 5px;

    h1, h2, h3, h4 {
        color:#121744; 
    }

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .header {
            display: flex;
            
            .header-section {
                padding-right: 20px;
            }
        }
    }

    .invoice-table {
        padding: 20px 0;
    }

    .invoice-signitory {
        padding: 10px;

        .signature-line {
            height: 2px;
            width: 200px;
            background-color: #121744;
        }
    }
`

const Table = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    th { 
        font-weight: bold;
        cursor: pointer;
    }

    th, td {
        padding: 1rem .8rem;
        border-bottom: 1px #f0f0f0 solid;
    }

    tbody {
        tr:nth-child(even){
            background-color: #f2f2f2;
        }
    }
`