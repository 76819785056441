import React, { useState } from "react";
import styled from "styled-components";

const currencies = [ 'KES', 'USD', 'AD'];
const branches = ['all', 'mombasa road', 'westlands', 'athi river'];

export default function Currencies () {
    const [ selectedCurrency, setSelecteddCurrency ] = useState('KES');
    const [ selectedBranch, setSelectedBranch ] = useState('all');

    return (
        <Styles>
            <p><b>Modify currencies</b></p>
            select currency
            <Select>
                    <select onChange={(e)=> setSelecteddCurrency(e.target.value)}>
                        <option defaultValue=''>{selectedCurrency}</option>
                        {currencies?.map((currency)=> (
                            <option value={currency} key={currencies.indexOf(currency)}>
                                {currency}
                            </option>
                        ))}
                    </select>
                </Select>
                apply to * branches
                <Select>
                    <select onChange={(e)=> setSelectedBranch(e.target.value)}>
                        <option defaultValue=''>{selectedBranch}</option>
                        {branches?.map((branch)=> (
                            <option value={branch} key={branches.indexOf(branch)}>
                                {branch}
                            </option>
                        ))}
                    </select>
                </Select>
                <button>apply</button>
        </Styles>
    )
}

const Styles = styled.div`
    padding: 10px;
    button {
        padding: 5px 20px;
        background-color: #0173f6;
        color: #ffffff;
        border: none;
        outline: 0;
        border-radius: 50px;
    }
`
const Select = styled.span`
    margin: 20px;

    select {
        /* border: none; */
        border: 2px #E6ECF1 solid;
        border-radius: 50px;
        padding: 0 10px;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        color: inherit;

        option {
            span {
                margin: .3rem;
            }
        }
    }
`