// onboarding page
import { useState } from "react";
import styled from "styled-components";
import { 
    OnboardingNavigation,
    Signup,
    TeamName,
    RoutesDefaults,
    TeamInvites
} from "../components";

const OnboardingPage = () => {
    const [ step, setStep ] = useState(1);

    // signup data
    const [ country, setCountry ] = useState('');
    const [selectedTimezone, setSelectedTimezone] =useState('')
    const [ username, setUserName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    // team naming  data
    const [ teamName, setTeamName ] = useState('');
    const [ teamSize, setTeamSize ] = useState('');
    const [ companyDescription, setCompanyDescription ] = useState('');

    // default routes  data
    const [selectedStartRoutes, setSelectedStartRoutes ] = useState<any>('');
    const [selectedFinishRoutes, setSelectedFinishRoutes ] = useState<any>('');

    // const [ startRoutes, setStartRoutes ] = useState<any>('');
    // const [ finishRoutes, setFinishRoutes ] = useState<any>('');
    const [ startTime, setStartTime ] = useState('10:00 AM');
    const [ finishTime, setFinishTime ] = useState('10:00 AM');
    const [ averageTimeAtStop, setAverageTimeAtStop ] = useState({ minutes:'', seconds:'' })

    // team invites data
    const team:any = [];
    const [ teamMembers, setTeamMembers ] = useState<any>([...team]);

    const signupPayload = {
        name: username,
        email: email,
        password: password,
        country: country,
        time_zone: selectedTimezone,
        organisation_name: teamName,
        organization_type: companyDescription,
        organization_team_size: teamSize,
        timezone: selectedTimezone,
        start_location: selectedStartRoutes.label,
        start_time: startTime,
        end_location: selectedFinishRoutes.label,
        end_time: finishTime,
        driver_time_per_stop: averageTimeAtStop,
        team_members: teamMembers,
    }

    return (
        <Styles>
            <div className="topbar">
                <img src={require("../assets/images/logo.png")} alt="logo" height={50} className="logo"/>
            </div>
            <div className="wrapper">
                <div className="section-one">
                    <OnboardingNavigation
                        step={step}
                    />
                </div>
                <div className="section-two">
                    {
                        step === 1 ? 
                            <Signup 
                                country={country}
                                selectedTimezone={selectedTimezone}
                                username={username}
                                email={email}
                                password={password}
                                setCountry={setCountry}
                                setSelectedTimezone={setSelectedTimezone}
                                setUserName={setUserName}
                                setEmail={setEmail}
                                setPassword={setPassword}
                                step={step}
                                setStep={setStep}
                            /> :
                        step === 2 ? 
                            <TeamName
                                teamName={teamName}
                                teamSize={teamSize}
                                companyDescription={companyDescription}
                                setTeamName={setTeamName}
                                setTeamSize={setTeamSize}
                                setCompanyDescription={setCompanyDescription}
                                step={step}
                                setStep={setStep}
                            /> :
                        step === 3 ? 
                            <RoutesDefaults
                                selectedStartRoutes={selectedStartRoutes}
                                setSelectedStartRoutes={setSelectedStartRoutes}
                                selectedFinishRoutes={selectedFinishRoutes}
                                setSelectedFinishRoutes={setSelectedFinishRoutes}
                                startTime={startTime}
                                finishTime={finishTime}
                                averageTimeAtStop={averageTimeAtStop}
                                setStartTime={setStartTime}
                                setFinishTime={setFinishTime}
                                setAverageTimeAtStop={setAverageTimeAtStop}
                                step={step}
                                setStep={setStep}
                            /> :
                        step === 4 ? 
                            <TeamInvites
                                teamMembers={teamMembers}
                                setTeamMembers={setTeamMembers}
                                step={step}
                                setStep={setStep}
                                signupPayload={signupPayload}
                            /> :
                        null
                    }
                </div>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    .topbar {
        top: 0;
        position: fixed;
        width: 100%;
        height: 70px;
        background-color: #f3f5f6;

        .logo {
            margin-top: 10px;
            margin-left: 20px;
        }
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 70px;
        padding-bottom: 70px;

        .section-one {
            flex: 30%;
            width: 30%;
            height: 100%;
            position: fixed;

            @media(max-width:1000px){
                display: none;
            }
        }
        .section-two {
            flex: 70%;
            width: 70%;
            margin-left: 30%;
            
            @media(max-width:1000px){
                margin-left: 0;
            }
        }
    }
    .navigator {
        bottom: 0;
        position: fixed;
        width: 100%;
        text-align: right;
        background-color: #f3f5f6;
    }
`

export default OnboardingPage;