// success message after rating
import styled from "styled-components";

export default function RatingSuccess ({children}:any){
    return (
        <Styles>
            <div  className="centered">
                {children}
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    .centered {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 5%;
        box-shadow: 5px 5px 25px 0px rgba(46, 61, 73, 0.2);
        border-radius: 5px;
    }
`