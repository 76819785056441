import React from "react";
import styled from "styled-components";
import Tooltip from "../../shared/Tooltip";

const DriverCostSummaryCards = () => {
    return (
        <Styles>
            <div className="summary-card">
                <h3>Total Cost</h3>
                <p>KES 104,000</p>
            </div>
            <Seperator/>
            <div className="summary-card">
                <h3>Total Paid</h3>
                <p>KES 20,000</p>
            </div>
            <Seperator/>
            <div className="summary-card">
                <h3>Total Unpaid</h3>
                <p>KES 84,000</p>
            </div>
            <Seperator/>
            <div className="summary-card">
                <h3>Weekly</h3>
                <p>
                    <img src={require('../../../assets/icons/trend-down.png')} className="trend-icon" alt="trend" height={30} />5% last 7 days
                    <Tooltip label="" toolTipInfo="Prev: KES 98,000 Current: KES 90,000"/>
                </p>
            </div>
            <Seperator/>
            <div className="summary-card">
                <h3>Monthly</h3>
                <p>
                    <img src={require('../../../assets/icons/trend-up.png')} className="trend-icon" alt="trend" height={30} /> 20% last 30 days
                    <Tooltip label="" toolTipInfo="Prev: KES 98,000 Current: KES 104,000"/>
                </p>
            </div>
        </Styles>
    )
}

const Styles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px #E6ECF1 solid;

    .summary-card {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 10px;
        /* border: 1px #E6ECF1 dashed;
        background-color: #f5f7fa; */
        margin: 10px;

        .trend-icon {
            margin-right: 10px;
            vertical-align: middle;
        }
    }
`
const Seperator = styled.div`
    background: #E6ECF1;
    width: 2px
`
export default DriverCostSummaryCards;