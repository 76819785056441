import styled from "styled-components";
import { MdError } from 'react-icons/md';

interface errorProps {
    errorMessage: any,
}

const ErrorCard = ({ errorMessage }:errorProps) => {
    return (
        <Styles>
            <MdError fontSize={50} color="red"/>
            <div>{errorMessage}</div>
        </Styles>
    )
}

const Styles = styled.div`
    text-align: center;
`

export default ErrorCard;